import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Box, Text } from 'rebass';
import { compose } from 'redux';

import AlertContext from 'components/AlertContext';
import Loader from 'components/Loader';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Nav from 'components/Nav';
import WithAlert from 'components/WithAlert';
import * as auth from 'redux/features/auth';

import Auth from './Auth';
import EnterQR from './Auth/components/EnterQR';
import Content from './Content';
import Payments from './Payments';
import Users from './Users';

const Reports = React.lazy(() => import('./Reports'));

const Containers = ({ authenticated, checkAuth, twoFactorSet }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const check = async () => {
      try {
        await checkAuth();
      } catch {
        // pass
      }

      setLoaded(true);
    };

    check();
  }, []);

  if (!loaded) {
    return (
      <div
        css={css`
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            height: 32px;
          `}
        >
          <Loader black />
        </div>
        <Text mt={2}>Checking your authorization...</Text>
      </div>
    );
  }

  return (
    <WithAlert>
      {(alert) => (
        <AlertContext.Provider value={alert}>
          <Switch>
            {!authenticated ? (
              <Route path="/auth" component={Auth} />
            ) : twoFactorSet ? (
              <Route
                path="/"
                render={(props) => (
                  <div
                    css={css`
                      display: flex;
                      flex: 1;
                      padding-left: 220px;
                      position: relative;
                    `}
                  >
                    <div
                      css={css`
                        position: fixed;
                        top: 40px;
                        left: 0;
                        bottom: 0;
                        width: 220px;
                        background: #373737;
                      `}
                    >
                      <Nav {...props} />
                    </div>
                    <Box width={1} flex={1} p={4} display="flex" flexDirection="column">
                      <Switch>
                        <Route path="/users" component={Users} />
                        <Route path="/admins" component={Users} />
                        <Route path="/course-completion" component={Users} />
                        <Route path="/payments" component={Payments} />
                        <Route
                          path="/reports"
                          render={(props) => (
                            <React.Suspense fallback={<LoadingPlaceholder />}>
                              <Reports {...props} />
                            </React.Suspense>
                          )}
                        />
                        <Route path="/content" component={Content} />
                        <Redirect to="/users" />
                      </Switch>
                    </Box>
                  </div>
                )}
              />
            ) : (
              <Route path="/" component={EnterQR} />
            )}
            <Redirect to={authenticated ? '/users' : '/auth'} />
          </Switch>
        </AlertContext.Provider>
      )}
    </WithAlert>
  );
};

Containers.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  checkAuth: PropTypes.func.isRequired,
};

export default compose(
  connect(
    (state) => ({
      authenticated: state.auth.authenticated,
      twoFactorSet: state.auth.user.twoFactorSet,
    }),
    {
      checkAuth: auth.checkAuth,
    }
  ),
  React.memo
)(Containers);
