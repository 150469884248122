import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as photosFeatures from 'redux/features/photos';

import AddPhoto from './components/AddPhoto';
import EditPhoto from './components/EditPhoto';
import { PhotosContext } from './components/PhotosContext';
import PhotosList from './components/PhotosList';
import QuizesAndReflections from './components/QuizesAndReflections';
import QuotesList from './components/QuotesList';
import TracksRouter from './components/Tracks';

const Content = ({ match }) => {
  const [value, setValue] = React.useState([]);
  const dispatch = useDispatch();

  const refetchAll = () => {
    dispatch(
      photosFeatures.list({
        params: {
          limit: 1000,
        },
      })
    ).then((action) => {
      if (!action.error) {
        setValue(action.payload.results);
      }
    });
  };

  React.useEffect(() => {
    refetchAll();
  }, []);

  return (
    <PhotosContext.Provider
      value={{
        refetch: refetchAll,
        results: value,
      }}
    >
      <Switch>
        <Route path={`${match.path}/photos/add`} component={AddPhoto} />
        <Route path={`${match.path}/photos/:pk`} component={EditPhoto} />
        <Route path={`${match.path}/photos`} component={PhotosList} />
        <Route path={`${match.path}/quotes`} component={QuotesList} />
        <Route path={`${match.path}/quizes-and-reflections`} component={QuizesAndReflections} />
        <TracksRouter match={match} />
        <Redirect to={`${match.url}/quotes`} />
      </Switch>
    </PhotosContext.Provider>
  );
};

export default React.memo(Content);
