import styled from '@emotion/styled';
import { NavLink as RouterNavLink } from 'react-router-dom';

export const NavLink = styled(RouterNavLink)`
  display: block;
  width: 100%;

  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
  }
`;
