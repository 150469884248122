import { USER_ROLE } from 'redux/enums';
import * as auth from 'redux/features/auth';
import * as types from 'redux/types/auth';

const initialState = {
  authenticated: false,
  token: '',
  refreshToken: '',
  user: {},
};

const authReducer = (state = initialState, action) => {
  if (
    (action.type === auth.obtainToken.success || action.type === auth.refreshToken.success) &&
    [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(action.payload.user.userType)
  ) {
    return {
      authenticated: true,
      token: action.payload.token,
      refreshToken: action.payload.refreshToken,
      user: action.payload.user,
    };
  }

  if (action.type === types.setTokens) {
    return {
      ...state,
      authenticated: true,
      token: action.payload.access,
      refreshToken: action.payload.refresh,
    };
  }

  if (action.type === auth.getMe.success) {
    return {
      ...state,
      user: action.payload,
    };
  }

  if (action.type === auth.signout) {
    return initialState;
  }

  return state;
};

export default authReducer;
