import { camelizeKeys, decamelizeKeys } from 'humps';

const prefix = '@@api';

class TimeoutError extends Error {}

const stringifyQuery = (query) => {
  const params = new URLSearchParams();

  Object.keys(query).forEach((key) => {
    params.append(key, query[key]);
  });

  return params.toString();
};

const createRequest = (action) => {
  const type = {
    reducer: action.type,
    request: `${prefix}/${action.type}/request`,
    success: `${prefix}/${action.type}/success`,
    failure: `${prefix}/${action.type}/failure`,
  };

  const generatedAction = (request = {}) => async (dispatch, getState) => {
    dispatch({
      type: type.request,
      meta: {
        ...request,
        [prefix]: type,
      },
    });

    const { token } = getState().auth;

    try {
      let { headers } = request;

      const isFormData = request.body instanceof FormData;

      headers = {
        ...headers,
      };

      if (!isFormData) {
        headers['Content-Type'] = 'application/json';
      }

      if (token) {
        headers = {
          ...headers,
          Authorization: `JWT ${token}`,
        };
      }

      const params = request.params ? `?${stringifyQuery(request.params)}` : '';

      const fetchRequest = await Promise.race([
        fetch(
          typeof action.url === 'function'
            ? action.url({
                action,
                request,
                params,
                api: process.env.REACT_APP_API_PATH,
              })
            : `${process.env.REACT_APP_API_PATH}/${action.url}/${params}`,
          {
            headers,
            body: isFormData ? request.body : JSON.stringify(decamelizeKeys(request.body)),
            mode: 'cors',
            method: action.method.toUpperCase(),
            signal: request.signal,
          }
        ),
        new Promise((_, reject) => setTimeout(() => reject(new TimeoutError()), 120000)),
      ]);

      let json;
      try {
        json = await fetchRequest.json();
        json = camelizeKeys(json);
      } catch (e) {
        json = {};
      }

      if (process.env.NODE_ENV === 'development') {
        console.log(fetchRequest);
        console.log(json);
      }

      const result = {
        type: fetchRequest.status >= 200 && fetchRequest.status < 300 ? type.success : type.failure,
        payload: json,
        meta: {
          ...request,
          status: fetchRequest.status,
          [prefix]: type,
        },
      };

      if (result.type === type.failure) {
        result.error = true;
      }

      dispatch(result);

      return Promise.resolve(result);
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.log(e);
      }

      const rejectAction = {
        type: type.failure,
        payload: e,
        meta: {
          ...request,
          status: 0,
          [prefix]: type,
        },
        error: true,
      };

      if (e instanceof TimeoutError) {
        rejectAction.meta.status = -1;
      }

      dispatch(rejectAction);

      return Promise.resolve(rejectAction);
    }
  };

  generatedAction.request = type.request;
  generatedAction.success = type.success;
  generatedAction.failure = type.failure;
  generatedAction.reducer = type.reducer;
  generatedAction.select = (state) => state.api[type.reducer];

  return generatedAction;
};

export default createRequest;
