import * as user from 'redux/features/user';

const initialState = {
  data: {},
};

const authReducer = (state = initialState, action) => {
  if (action.type === user.get.success || action.type === user.patch.success) {
    return {
      data: {
        ...state.data,
        [action.payload.id]: action.payload,
      },
    };
  }

  return state;
};

export default authReducer;
