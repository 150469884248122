import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'users/list',
  url: 'admin/users',
  method: 'get',
});

export const get = createRequest({
  type: 'users/get',
  url: ({ api, request }) => `${api}/admin/users/${request.pk}/`,
  method: 'get',
});

export const del = createRequest({
  type: 'users/delete',
  url: ({ api, request }) => `${api}/admin/users/${request.pk}/`,
  method: 'delete',
});

export const patch = createRequest({
  type: 'users/patch',
  url: ({ api, request }) => `${api}/admin/users/${request.pk}/`,
  method: 'patch',
});

export const post = createRequest({
  type: 'users/post',
  url: 'admin/users',
  method: 'post',
});

export const setPassword = createRequest({
  type: 'users/set-password',
  url: ({ api, request }) => `${api}/admin/users/${request.pk}/set_password/`,
  method: 'patch',
});
