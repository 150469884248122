import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import format from 'date-fns/format';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import NavLink from 'components/NavLink';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import * as discountFeatures from 'redux/features/discount';
import * as productFeatures from 'redux/features/product';
import createFormError, { errorToString } from 'utils/createFormError';

import DiscountForm from '../DiscountForm';

const EditDiscountCode = ({ match, history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [discount, setDiscount] = React.useState(null);
  const [editable, setEditable] = React.useState(false);

  React.useEffect(() => {
    Promise.all([
      dispatch(
        discountFeatures.get({
          pk: match.params.pk,
        })
      ),
      dispatch(
        productFeatures.list({
          params: {
            limit: 999,
            offset: 0,
          },
        })
      ),
    ]).then((actions) => {
      if (actions.every(({ error }) => !error)) {
        setDiscount(actions[0].payload);
      } else {
        alert.onFailure(
          actions
            .filter(({ error }) => error)
            .map(errorToString)
            .join(' ')
        );
        history.push('/payments');
      }
    });
  }, []);

  if (!discount) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box variant="containerBox" p={3}>
      <DocumentTitle>Edit Discount Code</DocumentTitle>
      <Link to="/payments">
        <NavLink>BACK TO SEARCH</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Edit Discount Code
      </Heading>
      <Form
        onSubmit={async ({ discountType, value, percent, expiresAt, ...values }) => {
          const body = {
            ...values,
            expiresAt: expiresAt ? format(expiresAt, 'yyyy-MM-dd') : null,
          };

          if (discountType === '0') {
            body.value = value ? Number(value) * 100 : 0;
            body.percent = 0;
          } else {
            body.value = 0;
            body.percent = percent || 0;
          }

          const action = await dispatch(
            discountFeatures.patch({
              body,
              pk: discount.id,
            })
          );

          if (!action.error) {
            alert.onSuccess('Discount Code has been updated.');
            setEditable(false);
            setDiscount(action.payload);
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          code: discount.code,
          expiresAt: discount.expiresAt ? new Date(discount.expiresAt) : null,
          comment: discount.comment,
          limit: discount.limit,
          percent: discount.percent,
          value: discount.value / 100,
          discountType: discount.value > 0 ? '0' : '1',
          products: discount.products.map(({ id }) => id),
          free: discount.free,
        }}
        subscription={{
          ...FormError.formSubscriptions,
          values: true,
        }}
        mutators={arrayMutators}
      >
        {({ handleSubmit, values, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <DiscountForm values={values} editable={editable} />
            <Flex mt={3}>
              <FormError meta={meta} />
            </Flex>
            {editable && (
              <Flex mt={4}>
                <Box width={1 / 2} mr={2}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setEditable(false);
                      form.reset();
                    }}
                    type="button"
                    disabled={meta.submitting}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={1 / 2} ml={2}>
                  <Button variant="primary" type="submit" loading={meta.submitting}>
                    Save Changes
                  </Button>
                </Box>
              </Flex>
            )}
          </form>
        )}
      </Form>
      {!editable && (
        <Flex alignItems="center" justifyContent="center" width={1} mt={4}>
          <Box mr={3}>
            <Button variant="nulled" width="initial" onClick={() => setEditable(true)}>
              <Flex alignItems="center">
                <Text color="secondary" mr={2}>
                  Edit Discount Code
                </Text>
                <img
                  alt="Edit Profile"
                  src={require('assets/pencil.svg')}
                  css={css`
                    width: 14px;
                    height: 13px;
                    object-fit: contain;
                  `}
                />
              </Flex>
            </Button>
          </Box>
          <Box ml={3}>
            <WithModal
              text="Are you sure you want to delete this discount code?"
              renderButtons={({ close }) => (
                <Flex>
                  <Box width={0.5}>
                    <Button variant="outline" onClick={close}>
                      Cancel
                    </Button>
                  </Box>
                  <Box width={0.5}>
                    <Button
                      variant="primary"
                      resolve={async () => {
                        dispatch(
                          discountFeatures.del({
                            pk: discount.id,
                          })
                        ).then((action) => {
                          if (!action.error) {
                            history.push('/payments');
                            alert.onSuccess('Discount code has been deleted successfully.');
                          } else {
                            close();
                            setTimeout(() => alert.onFailure(errorToString(action)), 200);
                          }
                        });
                      }}
                    >
                      Delete Discount
                    </Button>
                  </Box>
                </Flex>
              )}
            >
              {({ open }) => (
                <Button variant="nulled" onClick={open}>
                  <Flex alignItems="center">
                    <Text color="secondary" mr={2}>
                      Delete Discount Code
                    </Text>
                    <img
                      alt="Delete Discount Code"
                      src={require('assets/delete.svg')}
                      css={css`
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                      `}
                    />
                  </Flex>
                </Button>
              )}
            </WithModal>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

EditDiscountCode.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(EditDiscountCode);
