import React from 'react';
import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';

export const Line = styled.div`
  flex: 1;
  height: 2px;
  background: #000;
`;

const Separator = (props) => (
  <Flex my={4} alignItems="center" {...props}>
    <Line />
    <Text mx={3} variant="subtitle">
      {props.children}
    </Text>
    <Line />
  </Flex>
);

export default React.memo(Separator);
