import React from 'react';
import { Field, Form } from 'react-final-form';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import Button from 'components/Button';
import FormError from 'components/FormError';
import Input from 'components/Input';
import useUserRoute from 'hooks/useUserRoute';

const SearchFilter = ({ resetPage }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const params = new URLSearchParams(history.location.search);
  const [getRoute] = useUserRoute();

  return (
    <Form
      onSubmit={({ search }) => {
        resetPage();
        params.set('search', search);
        history.push(`${match.url}?${params.toString()}`);
      }}
      initialValues={{
        search: params.get('search') || '',
      }}
      subscription={{
        ...FormError.formSubscription,
        values: true,
      }}
    >
      {({ handleSubmit, values, ...meta }) => (
        <Box as="form" onSubmit={handleSubmit} mb={2}>
          <Flex>
            <Flex flex="1">
              <Box width={1}>
                <Field
                  name="search"
                  component={Input}
                  placeholder="Search by First Name, Last Name or Email"
                  autofocus=""
                />
              </Box>
            </Flex>
            <Flex>
              <Button m={2} p={2} width="100px" height="42px" loading={meta.submitting} type="submit">
                Search
              </Button>
              <Button
                m={2}
                p={2}
                width="160px"
                height="42px"
                onClick={() => {
                  params.set('page', 1);
                  params.set('search', '');

                  history.push(`${match.url}?${params.toString()}`);
                }}
              >
                Clear Filters
              </Button>
              <Link to={getRoute('add')}>
                <Button variant="secondary" p={2} m={2} width="160px" height="42px">
                  Add New User +
                </Button>
              </Link>
            </Flex>
          </Flex>
          <FormError meta={meta} />
        </Box>
      )}
    </Form>
  );
};

export default React.memo(SearchFilter);
