import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import TippedLabel from 'components/TippedLabel';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import * as quotesFeatures from 'redux/features/quotes';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const BulkUploadQuotes = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);

  return (
    <Flex mt={4} flexDirection="column">
      <Heading fontSize={3} mb={2}>
        BULK UPLOAD OF QUOTES
      </Heading>
      <Form
        onSubmit={async ({ csv }, form) => {
          const formData = new FormData();

          formData.append('csv', csv[0]);

          const action = await dispatch(
            quotesFeatures.bulkUpload({
              body: formData,
            })
          );

          if (!action.error) {
            alert.onSuccess('Quotes have been replaced.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex flexDirection="row" alignItems="center">
              <Flex mr={3} width={122}>
                <TippedLabel>Upload Quotes:</TippedLabel>
              </Flex>
              <Flex>
                <Field
                  name="csv"
                  component={FileInput}
                  validate={checkRequired}
                  type="file"
                  accept="text/csv"
                  multiple={false}
                />
              </Flex>
              <Flex>
                <WithModal
                  text="Are you sure you want to replace all the quotes on the app?"
                  renderButtons={({ close }) => (
                    <Flex>
                      <Box width={0.5}>
                        <Button
                          variant="primary"
                          onClick={() => {
                            close();
                            form.submit();
                          }}
                        >
                          Yes
                        </Button>
                      </Box>
                      <Box width={0.5}>
                        <Button variant="outline" onClick={close} type="button">
                          Cancel
                        </Button>
                      </Box>
                    </Flex>
                  )}
                >
                  {({ open }) => (
                    <Box mx={3}>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={open}
                        loading={meta.submitting}
                        css={css`
                          padding: 12px 16px;
                        `}
                      >
                        Upload
                      </Button>
                    </Box>
                  )}
                </WithModal>
              </Flex>
              <Flex>
                <TippedLabel tip="When you upload a new CSV file it will replace all the quotes on the app." />
              </Flex>
            </Flex>
            <FormError meta={meta} />
          </form>
        )}
      </Form>
      <Flex flexDirection="row" alignItems="center" mt={2}>
        <Flex width={122} mr={3}>
          <Text>Template File:</Text>
        </Flex>
        <Flex>
          <Button
            primary
            resolve={async () => {
              try {
                const response = await fetch(process.env.REACT_APP_API_PATH + '/admin/quotes/csv_file/', {
                  headers: {
                    Authorization: `JWT ${token}`,
                  },
                });

                if (response.status === 200) {
                  const blob = await response.blob();
                  const a = document.createElement('a');

                  a.href = window.URL.createObjectURL(blob);
                  a.download = 'quotes template.csv';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                } else {
                  alert.onFailure('API error has occured while downloading the template file.');
                }
              } catch {
                alert.onFailure('Unknown error has occured while downloading the template file.');
              }
            }}
            css={css`
              padding: 12px 16px;
            `}
          >
            Download CSV File
          </Button>
        </Flex>
        <Flex ml={3}>Download template CSV file for upload</Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(BulkUploadQuotes);
