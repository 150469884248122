import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';

import Loader from '../Loader';

const LoadingPlaceholder = ({ multiple }) => (
  <Flex flex={1} p={5} justifyContent="center" alignItems="center" flexDirection="column">
    <Box>
      <Loader black />
    </Box>
    <Text mt={2}>Loading item{multiple ? 's' : ''}...</Text>
  </Flex>
);

LoadingPlaceholder.propTypes = {
  multiple: PropTypes.bool,
};

export default React.memo(LoadingPlaceholder);
