import React from 'react';
import PropTypes from 'prop-types';

import Portal from 'components/Portal';

const LazyAlert = React.lazy(() => import('./lazy'));

const WithAlert = ({ children }) => {
  const [state, setState] = React.useState({
    isOpen: false,
    isError: false,
    message: '',
  });

  const close = () => {
    setState({
      isOpen: false,
      isError: state.isError,
      message: state.message,
    });
  };

  const onSuccess = (messageToShow) => {
    setState({
      isOpen: true,
      isError: false,
      message: messageToShow,
    });

    setTimeout(close, 3000);
  };

  const onFailure = (messageToShow) => {
    setState({
      isOpen: true,
      isError: true,
      message: messageToShow,
    });

    setTimeout(close, 3000);
  };

  return (
    <>
      {children({ onSuccess, onFailure })}
      <Portal selector="#alert-root">
        <React.Suspense fallback={null}>
          <LazyAlert state={state} close={close} />
        </React.Suspense>
      </Portal>
    </>
  );
};

WithAlert.propTypes = {
  children: PropTypes.func.isRequired,
};

export default React.memo(WithAlert);
