import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Button, Flex, Text } from 'rebass';

import * as Styled from './styles';

const defaultItemsPerPage = [10, 25, 100, 250];

const TableControls = ({ page, count, limit, setPage, setLimit, itemsPerPage = defaultItemsPerPage }) => (
  <Flex width={1} mt={3}>
    <Flex mr={3} alignItems="center">
      <Text variant="caption" mr={2}>
        Items per page:
      </Text>
      <select value={limit} onChange={(e) => setLimit(e.target.value)}>
        {itemsPerPage.map((v) => (
          <option value={v} key={v}>
            {v}
          </option>
        ))}
      </select>
    </Flex>
    <Flex>
      <Styled.Line />
    </Flex>
    <Flex mx={3} alignItems="center">
      <Text variant="caption" color="#525252">
        {page * limit + 1} – {Math.min((page + 1) * limit, count)} of {count} items
      </Text>
    </Flex>
    <Flex flex="1" justifyContent="flex-end" alignItems="center">
      <Text variant="caption">
        {page + 1} of {Math.ceil(count / limit)} pages
      </Text>
    </Flex>
    <Flex ml={3}>
      <Flex mr={3}>
        <Styled.Line />
      </Flex>
      <Flex width="64px">
        <Button
          variant="nulled"
          p={1}
          aria-label="Previous Page"
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          css={
            page === 0 &&
            css`
              cursor: not-allowed;
              opacity: 0.2;
            `
          }
        >
          <Styled.PreviousButton alt="Previous Page" src={require('assets/nextpage.svg')} />
        </Button>
        <Button
          variant="nulled"
          p={1}
          aria-label="Next Page"
          onClick={() => {
            if (page < Math.ceil(count / limit) - 1) {
              setPage(page + 1);
            }
          }}
          css={
            page >= Math.ceil(count / limit) - 1 &&
            css`
              cursor: not-allowed;
              opacity: 0.2;
            `
          }
        >
          <Styled.NextButton alt="Previous Page" src={require('assets/nextpage.svg')} />
        </Button>
      </Flex>
    </Flex>
  </Flex>
);

TableControls.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default React.memo(TableControls);
