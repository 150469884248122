import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading } from 'rebass';

import DocumentTitle from 'components/DocumentTitle';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Select from 'components/Select';
import useAlert from 'hooks/useAlert';
import { QUIZ_TYPE } from 'redux/enums';
import * as coursesFeatures from 'redux/features/course';
import { checkRequired } from 'utils/validation';

import DisplayQuiz from './components/DisplayQuiz';

const initialValues = {
  course: '1',
  chapter: '1',
  lesson: '2',
};

const QuizesAndReflections = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [prevValues, setPrevValues] = React.useState(initialValues);
  const [loading, setLoading] = React.useState(true);
  const [courses, setCourses] = React.useState([]);
  const [chapters, setChapters] = React.useState([]);
  const [lessons, setLessons] = React.useState([]);
  const [quizzes, setQuizzes] = React.useState([]);
  const [quizQuestions, setQuizQuestions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const result = await Promise.all([
        dispatch(
          coursesFeatures.list({
            params: {
              limit: 100,
            },
          })
        ),
        dispatch(
          coursesFeatures.getChapters({
            params: {
              limit: 500,
            },
          })
        ),
        dispatch(
          coursesFeatures.getLessons({
            params: {
              limit: 1000,
            },
          })
        ),
        dispatch(
          coursesFeatures.getQuizzes({
            params: {
              limit: 2000,
            },
          })
        ),
        dispatch(
          coursesFeatures.getQuestions({
            params: {
              limit: 100000,
            },
          })
        ),
      ]);

      if (result.some(({ error }) => error)) {
        return alert.onFailure('Error has occured while loading the data.');
      }

      setCourses(result[0].payload.results);
      setChapters(result[1].payload.results);
      setLessons(result[2].payload.results);
      setQuizzes(result[3].payload.results);
      setQuizQuestions(result[4].payload.results);

      setLoading(false);
    })();
  }, []);

  const getQuizzes = () =>
    Promise.all([
      dispatch(
        coursesFeatures.getQuizzes({
          params: {
            limit: 2000,
          },
        })
      ),
      dispatch(
        coursesFeatures.getQuestions({
          params: {
            limit: 100000,
          },
        })
      ),
    ]).then((results) => {
      if (results.some(({ error }) => error)) {
        alert.onFailure('Error has occured while loading a quiz data. Please, reload a page.');
      } else {
        setQuizQuestions(results[1].payload.results);
        setQuizzes(results[0].payload.results);
      }
    });

  const findElement = React.useCallback((arr, lookingFor) => arr.find(({ id }) => id.toString() === lookingFor), []);

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box variant="containerBox" position="relative">
      <DocumentTitle>Edit Quiz or Reflection</DocumentTitle>
      <Heading fontSize={3}>CONTENT</Heading>
      <Flex my={3}>
        <Heading>QUIZ & REFLECTION</Heading>
      </Flex>
      <Form onSubmit={() => {}} initialValues={initialValues}>
        {({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Field name="course" component={Select} label="Select Course" validate={checkRequired}>
                <option value="">Select Course</option>
                {courses.map(({ id, name, shortName }) => (
                  <option value={id} key={id}>
                    {name} {shortName ? `(${shortName})` : ''}
                  </option>
                ))}
              </Field>
            </Box>
            <Box mt={2}>
              <Field
                name="chapter"
                component={Select}
                label="Select Chapter"
                disabled={!values.course}
                validate={checkRequired}
              >
                <option value="">Select Chapter</option>
                {values.course &&
                  chapters
                    .filter(({ course }) => course.toString() === values.course)
                    .map(({ id, name, order }) => (
                      <option value={id} key={id}>
                        Chapter {order + 1}: {name}
                      </option>
                    ))}
              </Field>
            </Box>
            <Box mt={2}>
              <Field
                name="lesson"
                component={Select}
                label="Select Lesson"
                disabled={!values.chapter}
                validate={checkRequired}
              >
                <option value="">Select Lesson</option>
                {values.chapter &&
                  lessons
                    .filter(({ chapter }) => chapter.toString() === values.chapter)
                    .map(({ id, name, order }) => (
                      <option value={id} key={id}>
                        Lesson {order + 1}: {name}
                      </option>
                    ))}
              </Field>
            </Box>
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                if (prevValues.course !== values.course) {
                  form.batch(() => {
                    form.change('chapter', '');
                    form.change('lesson', '');
                  });
                }

                if (prevValues.chapter !== values.chapter) {
                  form.change('lesson', '');
                }

                setPrevValues(values);
              }}
            />
          </form>
        )}
      </Form>
      {prevValues.course && prevValues.chapter && prevValues.lesson && (
        <Box>
          <Box my={3}>
            <hr />
          </Box>

          <DisplayQuiz
            course={findElement(courses, prevValues.course)}
            chapter={findElement(chapters, prevValues.chapter)}
            lesson={findElement(lessons, prevValues.lesson)}
            quizzes={quizzes}
            quizQuestions={quizQuestions}
            type={QUIZ_TYPE.QUIZ}
            getQuizzes={getQuizzes}
          />

          <Box my={3}>
            <hr />
          </Box>
          <DisplayQuiz
            course={findElement(courses, prevValues.course)}
            chapter={findElement(chapters, prevValues.chapter)}
            lesson={findElement(lessons, prevValues.lesson)}
            quizzes={quizzes}
            quizQuestions={quizQuestions}
            type={QUIZ_TYPE.REFLECTION}
            getQuizzes={getQuizzes}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(QuizesAndReflections);
