import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import TippedLabel from 'components/TippedLabel';
import useAlert from 'hooks/useAlert';
import createFormError from 'utils/createFormError';
import request, { matchError } from 'utils/request';
import { checkRequired } from 'utils/validation';

const BulkUpload = () => {
  const alert = useAlert();
  const token = useSelector((state) => state.auth.token);

  return (
    <Flex mt={4} flexDirection="column" mb={4}>
      <Heading mb={2} fontSize={3}>
        BULK UPLOAD OF AUDIO TRACKS
      </Heading>
      <Form
        onSubmit={async ({ images, csv }, form) => {
          const formData = new FormData();

          formData.append('csv', csv[0]);

          images.forEach((image, index) => {
            formData.append(`audio_${index}`, image);
          });

          const action = await request('admin/tracks/upload', {
            body: formData,
            method: 'POST',
            timeout: 1000 * 60 * 30,
          });

          if (!matchError(action)) {
            alert.onSuccess('Audio library has been updated.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          images: [],
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={2} flexDirection="row" alignItems="center">
              <Flex width={160} mr={3}>
                <TippedLabel>Select Audio Files:</TippedLabel>
              </Flex>
              <Flex flexDirection="column">
                <Box width={1}>
                  <Field name="images" component={FileInput} type="file" accept="*" multiple validate={checkRequired} />
                </Box>
              </Flex>
            </Flex>
            <Flex mt={2} flexDirection="row" alignItems="center">
              <Flex width={160} mr={3}>
                <TippedLabel>Upload Template:</TippedLabel>
              </Flex>
              <Flex flexDirection="column">
                <Box>
                  <Field
                    name="csv"
                    component={FileInput}
                    type="file"
                    accept="text/csv"
                    multiple={false}
                    validate={checkRequired}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box mx={3}>
                  <Button
                    variant="primary"
                    type="submit"
                    loading={meta.submitting}
                    css={css`
                      padding: 12px 16px;
                    `}
                  >
                    Upload
                  </Button>
                </Box>
              </Flex>
              <Flex>
                <TippedLabel
                  tip={`Select one or more images at the same time (bulk upload). Once the images selected, select the CSV files with captions. Then click "upload" to upload all photos and CSV fie.`}
                />
              </Flex>
            </Flex>
            <FormError meta={meta} />
          </form>
        )}
      </Form>
      <Flex flexDirection="row" alignItems="center" mt={2}>
        <Flex width={160} mr={3}>
          <Text>Template File:</Text>
        </Flex>
        <Flex>
          <Button
            primary
            resolve={async () => {
              try {
                const response = await fetch(process.env.REACT_APP_API_PATH + '/admin/tracks/csv_file/', {
                  headers: {
                    Authorization: `JWT ${token}`,
                  },
                });

                if (response.status === 200) {
                  const blob = await response.blob();
                  const a = document.createElement('a');

                  a.href = window.URL.createObjectURL(blob);
                  a.download = 'tracks template.csv';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                } else {
                  alert.onFailure('API error has occured while downloading the template file.');
                }
              } catch {
                alert.onFailure('Unknown error has occured while downloading the template file.');
              }
            }}
            css={css`
              padding: 12px 16px;
            `}
          >
            Download CSV File
          </Button>
        </Flex>
        <Flex ml={3}>Download template CSV file for upload</Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(BulkUpload);
