import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'qoutes/list',
  url: 'admin/quotes',
  method: 'get',
});

export const get = createRequest({
  type: 'quotes/get',
  url: ({ api, request }) => `${api}/admin/quotes/${request.pk}/`,
  method: 'get',
});

export const del = createRequest({
  type: 'quotes/delete',
  url: ({ api, request }) => `${api}/admin/quotes/${request.pk}/`,
  method: 'delete',
});

export const patch = createRequest({
  type: 'quotes/patch',
  url: ({ api, request }) => `${api}/admin/quotes/${request.pk}/`,
  method: 'patch',
});

export const post = createRequest({
  type: 'quotes/post',
  url: 'admin/quotes',
  method: 'post',
});

export const bulkUpload = createRequest({
  type: 'quotes/bulk-upload',
  url: 'admin/quotes/upload',
  method: 'post',
});
