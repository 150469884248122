import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TableControls from 'components/TableControls';
import useAlert from 'hooks/useAlert';
import * as quotesFeatures from 'redux/features/quotes';
import { errorToString } from 'utils/createFormError';

import AddQuote from './components/AddQuote';
import BulkUploadQuotes from './components/BulkUploadQuotes';
import EditQuotes from './components/EditQuotes';

const HR = styled.hr`
  margin: 32px 0;
`;

const QuotesList = ({ history, match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const quotesLoading = useSelector((state) => quotesFeatures.list.select(state)?.loading);

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = params.get('page');
    return pageParam ? Number(pageParam) - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = params.get('limit');
    return pageParam ? Number(pageParam) : 25;
  });

  const [data, setData] = React.useState({});

  const loadData = () => {
    params.set('limit', limit);
    params.set('page', page + 1);

    history.push(`${match.url}?${params.toString()}`);

    dispatch(
      quotesFeatures.list({
        params: {
          limit,
          offset: page * limit,
          text__icontains: params.get('search') || '',
        },
      })
    ).then((result) => {
      if (result.error) {
        alert.onFailure(errorToString(result));
      } else {
        setData({
          ...data,
          count: result.payload.count,
          [page]: result.payload.results,
        });
      }
    });
  };

  React.useEffect(() => {
    loadData();
  }, [page, limit, params.get('search')]);

  return (
    <Box variant="containerBox" position="relative" maxWidth={1200}>
      <DocumentTitle>Search Quotes</DocumentTitle>
      <Heading>CONTENT - QUOTES</Heading>
      <BulkUploadQuotes />
      <HR />
      <AddQuote />
      <HR />
      <Box>
        <Heading fontSize={3}>VIEW AND EDIT ALL QUOTES</Heading>
      </Box>
      <Flex flexDirection="row" mt={2}>
        <Flex id="submit-portal" alignItems="center" />
        <Form
          onSubmit={({ search }) => {
            setPage(0);
            setData({
              count: data.count,
            });

            params.set('search', search || '');
            history.push(`${match.url}?${params.toString()}`);
          }}
          initialValues={{
            search: params.get('search') || '',
          }}
        >
          {({ handleSubmit }) => (
            <Flex flexDirection="row" as="form" onSubmit={handleSubmit} flex={0.4}>
              <Flex flex={1}>
                <Box width={1}>
                  <Field component={Input} name="search" placeholder="Search by quote text" />
                </Box>
              </Flex>
              <Flex>
                <Box py={2} ml={3}>
                  <Button
                    css={css`
                      padding: 9px 16px;
                    `}
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Form>
      </Flex>
      <Box mt={2}>
        <Flex>
          <Box variant="th" width={140} />
          <Box variant="th" width={0.4}>
            Author
          </Box>
          <Box variant="th" width={1}>
            Description
          </Box>
        </Flex>
        <Flex flexWrap="wrap">
          {data[page] ? (
            <EditQuotes data={data[page]} triggerUpdate={loadData} />
          ) : (
            quotesLoading && <LoadingPlaceholder />
          )}
        </Flex>
      </Box>
      {data.count ? (
        <TableControls
          page={page}
          limit={limit}
          count={data.count}
          setPage={(page) => {
            if (!document.getElementById('submit-portal').childNodes.length) {
              setPage(page);
            }
          }}
          setLimit={(l) => {
            if (!document.getElementById('submit-portal').childNodes.length) {
              setLimit(l);

              if (page * l > data.count) {
                setPage(Math.floor(data.count / l));
              }

              setData({
                count: data.count,
              });
            }
          }}
        />
      ) : (
        !quotesLoading && (
          <Flex p={4} py={5} alignItems="center" justifyContent="center">
            <Text>No matching items found.</Text>
          </Flex>
        )
      )}
    </Box>
  );
};

QuotesList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default React.memo(QuotesList);
