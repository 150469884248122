import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { shallowEqual, useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import DatepickerInput from 'components/DatepickerInput';
import FieldError from 'components/FieldError';
import Input from 'components/Input';
import Select from 'components/Select';
import Separator from 'components/Separator';
import { checkInt, checkRequired } from 'utils/validation';

const calculatePrice = ({ discountType, free, value = 0, percent = 0 }, product) => {
  if (free) {
    return 'Free';
  }

  if (discountType === '0') {
    const val = Math.round(product.fullPrice - Number(value) * 100) / 100;
    return `$${val < 0 ? '0' : val}`;
  }

  return `~ $${Math.floor(product.fullPrice * ((100 - percent) / 100)) / 100}`;
};

const DiscountForm = ({ editable, values }) => {
  const products = useSelector((state) => state.products.items, shallowEqual);
  const productsData = useSelector((state) => state.products.data, shallowEqual);

  return (
    <>
      <Flex mt={3}>
        <Box width={1}>
          <Field
            name="code"
            component={Input}
            label="Discount Code"
            disabled={!editable}
            placeholder="Enter Discount Code"
            tip="Enter a value that user should enter in order to apply the discount."
            validate={checkRequired}
          />
        </Box>
      </Flex>
      <Flex mt={3}>
        <Box width={1}>
          <Field
            name="comment"
            component={Input}
            label="Description"
            placeholder="Enter Discount Code Description"
            as="textarea"
            disabled={!editable}
            css={css`
              height: 160px;
            `}
            tip="This field is visible for staff users only."
          />
        </Box>
      </Flex>
      <Flex mt={3}>
        <Box width={1 / 2} mr={2}>
          <Field
            name="expiresAt"
            component={DatepickerInput}
            label="Expiry Date"
            disabled={!editable}
            tip="When the field is left blank, the discount code will last without an expiration date."
          />
        </Box>
        <Box width={1 / 2} ml={2}>
          <Field
            name="limit"
            component={Input}
            label="Usage Limit"
            placeholder="Enter usage limit"
            disabled={!editable}
            type="number"
            min="1"
            tip="When the field is left blank, there is no limit to how many times this code can be used."
          />
        </Box>
      </Flex>
      <Flex mt={3}>
        <Box width={1 / 2} mr={2}>
          <Field
            name="discountType"
            component={Select}
            label="Discount Type"
            disabled={!editable}
            validate={checkRequired}
            tip="In order to make the product completely free for user select the checkbox under this field."
          >
            <option value="0">Flat Rate</option>
            <option value="1">Percentage</option>
          </Field>
        </Box>
        <Box width={1 / 2} ml={2}>
          {values.discountType === '0' ? (
            <Field
              name="value"
              component={Input}
              label="Enter Amount in Dollars"
              placeholder="Enter Discount Amount in Dollars"
              disabled={!editable || values.free}
              validate={(value, allValues) => {
                if (!allValues.free) {
                  return checkRequired(value);
                }
              }}
              type="number"
              min="0"
              step="0.1"
              tip="This value cannot be higher than the cheapest price of the product."
            />
          ) : (
            <Field
              name="percent"
              component={Input}
              label="Enter Percentage"
              placeholder="Enter Discount Amount in Integer Numbers"
              disabled={!editable || values.free}
              validate={(value, allValues) => {
                if (!allValues.free) {
                  return checkInt(99)(value);
                }
              }}
              type="number"
              min="0"
              max="99"
              tip="Maximum value is 99%."
            />
          )}
        </Box>
      </Flex>
      <Flex mt={2}>
        <Box width={1 / 2}>
          <Field
            name="free"
            component={CheckboxInput}
            label="Discount makes the product free"
            disabled={!editable}
            type="checkbox"
          />
        </Box>
      </Flex>
      <Separator>Products</Separator>
      <FieldArray
        name="products"
        validate={(v) => {
          const reversed = v?.slice().reverse();

          return v?.reduce((pv, item, index) => {
            if (v.length - 1 - reversed.indexOf(item) !== index) {
              return [...pv, 'Products must be unique per each product. Please, select another product.'];
            }

            return [...pv, checkRequired(item)];
          }, []);
        }}
      >
        {({ fields, meta }) => (
          <>
            {fields.map((name, index) => {
              const selectedProduct = productsData[values.products[index]];

              return (
                <React.Fragment key={name}>
                  <Box width={1} mt={3}>
                    <Field name={name} component={Select} label={`Select Product ${index + 1}`} disabled={!editable}>
                      <option value="">Select Product</option>
                      {products.map(({ id, slug }) => (
                        <option key={id} value={id}>
                          {slug}
                        </option>
                      ))}
                    </Field>
                  </Box>
                  <Flex mt={3}>
                    <Box width={0.5} mr={2}>
                      Current Price:
                      {selectedProduct ? <strong> ${selectedProduct.fullPrice / 100}</strong> : ' –'}
                    </Box>
                    <Box width={0.5} ml={2}>
                      New Price:
                      {selectedProduct ? <strong> {calculatePrice(values, selectedProduct)}</strong> : ' –'}
                    </Box>
                  </Flex>
                  {editable && (
                    <Flex mt={3}>
                      <Box width={1 / 2}>
                        <Button variant="nulled" onClick={() => fields.remove(index)}>
                          <Flex alignItems="center">
                            <Text color="secondary" textAlign="Center" mr={2}>
                              Remove Product
                            </Text>
                            <img
                              alt="Remove Product"
                              src={require('assets/delete.svg')}
                              css={css`
                                height: 16px;
                                object-fit: contain;
                              `}
                            />
                          </Flex>
                        </Button>
                      </Box>
                    </Flex>
                  )}
                </React.Fragment>
              );
            })}
            <FieldError meta={meta} />
            {fields.length <= products.length && editable && (
              <Flex mt={3} justifyContent="center">
                <Box width={1 / 2} py={2}>
                  <Button variant="nulled" onClick={() => fields.push('')}>
                    <Text color="secondary" textAlign="center">
                      Add Product +
                    </Text>
                  </Button>
                </Box>
              </Flex>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};

DiscountForm.propTypes = {
  editable: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
};

export default React.memo(DiscountForm);
