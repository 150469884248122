import { combineReducers } from 'redux';

import { signout } from 'redux/types/auth';

import api from './api';
import auth from './auth';
import courses from './courses';
import products from './products';
import userProducts from './userProducts';
import users from './users';

const reducers = combineReducers({
  api,
  auth,
  courses,
  products,
  users,
  userProducts,
});

export default (state, action) => {
  if (action.type === signout) {
    return reducers(undefined, action);
  }

  return reducers(state, action);
};
