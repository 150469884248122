import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddDiscountCode from './components/AddDiscountCode';
import Discounts from './components/Discounts';
import EditDiscountCode from './components/EditDiscountCode';

const Payments = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/discounts/add`} component={AddDiscountCode} />
    <Route path={`${match.path}/discounts/:pk`} component={EditDiscountCode} />
    <Route path={`${match.path}/discounts`} component={Discounts} />
    <Redirect to={`${match.url}/discounts`} />
  </Switch>
);

Payments.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(Payments);
