import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import Input from 'components/Input';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import Select from 'components/Select';
import useAlert from 'hooks/useAlert';
import * as quotesFeatures from 'redux/features/quotes';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const AddQuote = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  return (
    <Flex flexDirection="column">
      <Heading fontSize={3} mb={2}>
        UPLOAD A SINGLE QUOTE
      </Heading>
      <Form
        onSubmit={async ({ id, country, ...body }, form) => {
          const action = await dispatch(
            quotesFeatures.post({
              body,
            })
          );

          if (!action.error) {
            alert.onSuccess('Quote has been created.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        subscription={Form.formSubscriptions}
        initialValues={{
          author: 'Dandapani',
        }}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex>
              <Box width={1}>
                <Field
                  name="text"
                  component={Input}
                  label="Quote Text"
                  placeholder="Enter text"
                  validate={checkRequired}
                  as="textarea"
                  css={css`
                    height: 120px;
                  `}
                />
              </Box>
            </Flex>
            <Flex flexDirection="row">
              <Flex flex={0.5} mr={4}>
                <Box width={1}>
                  <Field name="author" component={Select} label="Author" validate={checkRequired}>
                    <option value="">Select author</option>
                    <option value="Dandapani">Dandapani</option>
                    <option value="Gurudeva Subramuniyaswami">Gurudeva Subramuniyaswami</option>
                  </Field>
                </Box>
              </Flex>
              <Flex flex={0.5} alignItems="flex-end">
                <Box py={2}>
                  <Button
                    variant="primary"
                    type="submit"
                    loading={meta.submitting}
                    css={css`
                      padding: 9px 16px;
                    `}
                  >
                    Upload
                  </Button>
                </Box>
              </Flex>
            </Flex>
            <FormError meta={meta} />
          </form>
        )}
      </Form>
    </Flex>
  );
};

export default React.memo(AddQuote);
