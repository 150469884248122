import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import memo from 'memoize-one';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DatepickerInput from 'components/DatepickerInput';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import Input from 'components/Input';
import NavLink from 'components/NavLink';
import Select from 'components/Select';
import Separator from 'components/Separator';
import useAlert from 'hooks/useAlert';
import useUserRoute from 'hooks/useUserRoute';
import { USER_PRODUCT_ACCESS_TYPE, USER_ROLE, USER_TYPE } from 'redux/enums';
import * as country from 'redux/features/country';
import * as product from 'redux/features/product';
import * as user from 'redux/features/user';
import * as userProducts from 'redux/features/userProducts';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const selectCountries = memo((countries) =>
  Object.entries(countries).sort(([, name1], [, name2]) => (name1.attr > name2.attr ? -1 : 1))
);

const AddUser = ({ history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const userType = useSelector((state) => state.auth.user.userType);
  const countries = useSelector((state) => selectCountries(country.list.select(state)?.data || []), shallowEqual);
  const products = useSelector((state) => state.products.items, shallowEqual);

  React.useEffect(() => {
    (async () => {
      const results = await Promise.all([
        dispatch(
          country.list({
            params: {
              limit: 200,
            },
          })
        ),
        dispatch(
          product.list({
            params: {
              limit: 50,
            },
          })
        ),
      ]);

      if (results.some(({ error }) => error)) {
        alert.onFailure(
          results
            .filter(({ error }) => error)
            .map(errorToString)
            .join(' ')
        );
      }
    })();
  }, []);

  const [getRoute] = useUserRoute();

  return (
    <Box variant="containerBox">
      <DocumentTitle>Search Users</DocumentTitle>
      <Link to={getRoute('search')}>
        <NavLink>GO BACK TO USER SEARCH</NavLink>
      </Link>
      <Heading textAlign="center" my={5}>
        Add New User
      </Heading>
      <Form
        onSubmit={async ({ products, ...body }) => {
          const post = await dispatch(
            user.post({
              body,
            })
          );

          if (post.error) {
            return createFormError(post);
          }

          const createdProducts = await Promise.all(
            products.map((item) =>
              dispatch(
                userProducts.post({
                  body: {
                    ...item,
                    user: post.payload.id,
                    free: true,
                  },
                })
              )
            )
          );

          if (createdProducts.some(({ error }) => error)) {
            alert.onFailure(
              'Errors has occured. Please, proceed to user after examining the errors. The user was already created.'
            );
            return createdProducts.map((action) => (action.error ? createFormError(action) : {}));
          }

          alert.onSuccess('User has been created successfully');
          history.push(`/users/${post.payload.id}/profile`);
        }}
        mutators={arrayMutators}
        subscription={FormError.formSubscriptions}
        initialValues={{
          userType: 1,
          products: [],
        }}
      >
        {({ handleSubmit, ...meta }) => (
          <Box as="form" onSubmit={handleSubmit} mb={4}>
            <Field
              name="email"
              component={Input}
              validate={checkRequired}
              label="Email"
              placeholder="Enter email address"
            />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field
                  name="firstName"
                  component={Input}
                  validate={checkRequired}
                  label="First Name"
                  placeholder="Enter first name"
                />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="lastName"
                  component={Input}
                  validate={checkRequired}
                  label="Last Name"
                  placeholder="Enter last name"
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} pr={2}>
                <Field name="country" component={Select} validate={checkRequired} label="Country">
                  <option value="">Select country</option>
                  {countries.map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </Box>
              {userType === USER_ROLE.SUPER_ADMIN && (
                <Box width={1 / 2} pl={2}>
                  <Field name="userType" component={Select} label="User Type" validate={checkRequired}>
                    {USER_TYPE.map(([id, name]) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Field>
                </Box>
              )}
            </Flex>
            <Separator>Courses</Separator>
            <FieldArray
              name="products"
              validate={(values) => {
                const reversed = values?.slice().reverse();

                return values?.reduce(
                  (pv, item, index) => [
                    ...pv,
                    values.length - 1 - reversed.findIndex((i) => i.product === item.product) !== index
                      ? { product: 'Products must be unique per each product. Please, select another product.' }
                      : {},
                  ],
                  []
                );
              }}
            >
              {({ fields }) => (
                <Box>
                  {products.length !== fields.length && (
                    <Button
                      variant="secondary"
                      onClick={() =>
                        fields.push({
                          product: '',
                          freeType: USER_PRODUCT_ACCESS_TYPE[1][0],
                        })
                      }
                      p={2}
                      width={200}
                      type="button"
                    >
                      Add Product +
                    </Button>
                  )}
                  {fields.map((name, index) => (
                    <div key={name}>
                      <Flex mt={3}>
                        <Box width={1 / 2} mr={2}>
                          <Field
                            name={`${name}.product`}
                            component={Select}
                            validate={checkRequired}
                            label="Select Product"
                          >
                            <option value="">Select Product</option>
                            {products.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.slug}
                              </option>
                            ))}
                          </Field>
                        </Box>
                        <Box width={1 / 2} ml={2}>
                          <Field name={`${name}.freeType`} component={Select} label="Access Type">
                            {USER_PRODUCT_ACCESS_TYPE.slice(1, USER_PRODUCT_ACCESS_TYPE.length).map(
                              ([id, accessType]) => (
                                <option key={id} value={id}>
                                  {accessType}
                                </option>
                              )
                            )}
                          </Field>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box width={0.5} mr={2}>
                          <Field
                            name={`${name}.startedAt`}
                            component={DatepickerInput}
                            label="Purchase Date"
                            showTimeSelect
                            timeIntervals={10}
                            timeFormat="HH:mm"
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            maxDate={new Date()}
                          />
                        </Box>
                        <Box width={0.5} ml={2} mt={4}>
                          <Button mt={2} variant="delete" onClick={() => fields.remove(index)}>
                            Delete Course #{index + 1}
                          </Button>
                        </Box>
                      </Flex>
                    </div>
                  ))}
                </Box>
              )}
            </FieldArray>
            <FormError meta={meta} />
            <Flex mt={5}>
              <Box width={0.5} mr={2}>
                <Link to="/users">
                  <Button variant="outline">Cancel</Button>
                </Link>
              </Box>
              <Box width={0.5} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Create User
                </Button>
              </Box>
            </Flex>
          </Box>
        )}
      </Form>
    </Box>
  );
};

AddUser.propTypes = {
  history: PropTypes.object.isRequired,
};

export default React.memo(AddUser);
