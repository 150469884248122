export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const setTokens = ({ token, refreshToken }) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const getTokens = () => [localStorage.getItem(ACCESS_TOKEN), localStorage.getItem(REFRESH_TOKEN)];

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};
