import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';

const FieldError = ({ meta }) => {
  const fieldError = (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);

  if (fieldError) {
    return <Text variant="errorText">{meta.error || meta.submitError}</Text>;
  }

  return null;
};

FieldError.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool.isRequired,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool.isRequired,
  }).isRequired,
};

export default React.memo(FieldError);
