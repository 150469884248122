import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Input } from '@rebass/forms';
import { Box } from 'rebass';

import FieldError from '../FieldError';
import TippedLabel from '../TippedLabel';

const Datepicker = React.lazy(() => import('react-datepicker'));

const ProxyDatepickerInput = ({ label, input, meta, tip, showNow, ...props }) => {
  const fieldError = (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);

  return (
    <Box
      py={2}
      css={css`
        position: relative;

        .react-datepicker-wrapper {
          width: 100%;
        }
      `}
    >
      {label && (
        <TippedLabel tip={tip} htmlFor={`${input.name}-input`} mb={2}>
          {label}
          {showNow && (
            <span
              variant="nulled"
              onClick={
                !props.disabled &&
                (() => {
                  input.onChange(new Date());
                })
              }
              css={[
                css`
                  display: inline-block;
                  margin-left: 8px;
                `,
                !props.disabled &&
                  css`
                    color: #0f62fe;
                    cursor: pointer;
                  `,
              ]}
            >
              {' '}
              Now
            </span>
          )}
        </TippedLabel>
      )}
      <React.Suspense
        fallback={
          <Input {...props} {...input} id={`${input.name}-input`} variant={fieldError ? 'inputError' : 'input'} />
        }
      >
        <Datepicker
          {...input}
          todayButton="Today"
          selected={input.value}
          customInput={
            <Input
              variant={fieldError ? 'inputError' : 'input'}
              css={css`
                :disabled {
                  background-color: #f2f2f2;
                }
              `}
            />
          }
          {...props}
        />
      </React.Suspense>
      <img
        src={require('assets/calendar.svg')}
        alt="Select Date"
        css={css`
          position: absolute;
          top: ${label ? '51px' : '19px'};
          height: 20px;
          width: 20px;
          right: 8px;
          pointer-events: none;
          user-select: none;
        `}
        draggable={false}
      />
      <FieldError meta={meta} />
    </Box>
  );
};

ProxyDatepickerInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  tip: PropTypes.string,
};

export default React.memo(ProxyDatepickerInput);
