import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR from 'swr';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import { joinQuery, stringifyQuery } from 'utils/query';
import request, { matchError } from 'utils/request';

import BulkUpload from './components/BulkUpload';

const Img = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
`;

const IconButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TracksMain = ({ history, match }) => {
  const alert = useAlert();
  const query = new URLSearchParams(history.location.search);

  const playlists = useSWR(
    joinQuery(
      'admin/playlists',
      stringifyQuery({
        limit: 1000,
        name__icontains: query.get('playlistSearch') || '',
      })
    )
  );

  const tags = useSWR(
    joinQuery(
      'admin/tags',
      stringifyQuery({
        limit: 1000,
        name__icontains: query.get('tagSearch') || '',
      })
    )
  );

  return (
    <Box variant="containerBox" position="relative" maxWidth={1160}>
      <DocumentTitle>Playlists General</DocumentTitle>
      <Heading textAlign="center">Playlists General</Heading>
      <BulkUpload />
      <hr />
      <Box py={2} mb={3}>
        <Flex alignItems="center">
          <Flex flex={1}>
            <Heading>VIEW AND EDIT ALL PLAYLISTS</Heading>
          </Flex>
          <Box>
            <Flex>
              <Box mr={2}>
                <Button
                  variant="secondary"
                  as="a"
                  resolve={async () => {
                    const response = await request('admin/playlists/publish', {
                      method: 'PATCH',
                      body: {
                        items: playlists.data.results.filter(({ published }) => !published).map(({ id }) => id),
                        action: 'publish',
                      },
                    });

                    await playlists.mutate();

                    if (matchError(response)) {
                      alert.onFailure('Failed to publish all playlists.');
                    } else {
                      alert.onSuccess('Playlists have been published successfully.');
                    }
                  }}
                >
                  Publish playlists
                </Button>
              </Box>
              <Box>
                <Link to="/content/playlists/add">
                  <Button variant="secondary" as="a">
                    Add Playlist
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex width="600px" flexDirection="column">
          <Form
            onSubmit={({ search }) => {
              query.set('playlistSearch', search || '');
              history.push(`${match.url}?${query.toString()}`);
            }}
            initialValues={{
              search: query.get('playlistSearch') || '',
            }}
          >
            {({ handleSubmit }) => (
              <Flex flexDirection="row" as="form" onSubmit={handleSubmit} flex={0.4}>
                <Flex flex={1}>
                  <Box width={1}>
                    <Field component={Input} name="search" placeholder="Search by playlist name" />
                  </Box>
                </Flex>
                <Flex>
                  <Box py={2} ml={3}>
                    <Button
                      css={css`
                        padding: 9px 16px;
                      `}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Form>
        </Flex>
        <Flex width={600} flexDirection="column">
          <Flex
            flexDirection="row"
            css={css`
              border-bottom: 2px solid black;
            `}
          >
            <Flex flexBasis="100px" p={2}></Flex>
            <Flex flex={2} p={2}>
              <Heading fontSize={2}>Playlist</Heading>
            </Flex>
            <Flex flex={1} p={2}>
              <Heading fontSize={2}>Status</Heading>
            </Flex>
          </Flex>
          <Flex backgroundColor="#fff" flexDirection="column" maxHeight="320px" overflowY="auto">
            {playlists.data ? (
              playlists.data.count === 0 ? (
                <Text p={2}>No items have been found.</Text>
              ) : (
                playlists.data.results.map((playlist) => (
                  <Flex flexDirection="row" key={playlist.id}>
                    <Flex flexBasis="100px" p={2}>
                      <Link to={`/content/playlists/${playlist.id}`}>
                        <IconButton>
                          <Img src={require('assets/pencil.svg')} alt="Edit Playlist" />
                        </IconButton>
                      </Link>
                      <WithModal
                        text="Are you sure you want to delete this playlist?"
                        renderButtons={({ close }) => (
                          <Flex>
                            <Box width={0.5}>
                              <Button variant="outline" onClick={close}>
                                Cancel
                              </Button>
                            </Box>
                            <Box width={0.5}>
                              <Button
                                variant="primary"
                                resolve={async () => {
                                  const response = await request(`/admin/playlists/${playlist.id}/`, {
                                    method: 'DELETE',
                                  });

                                  if (matchError(response)) {
                                    alert.onFailure(response.toString());
                                  } else {
                                    await playlists.mutate();
                                  }
                                }}
                              >
                                Delete Playlist
                              </Button>
                            </Box>
                          </Flex>
                        )}
                      >
                        {({ open }) => (
                          <Button
                            variant="nulled"
                            ml={2}
                            onClick={open}
                            css={css`
                              padding: 0;
                              height: 32px;
                              width: 32px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img
                              src={require('assets/delete.svg')}
                              css={css`
                                width: 32px;
                                height: 20px;
                              `}
                              alt="Delete Playlist"
                            />
                          </Button>
                        )}
                      </WithModal>
                    </Flex>
                    <Flex flex={2} p={2}>
                      <Text>{playlist.name}</Text>
                    </Flex>
                    <Flex flex={1} p={2}>
                      <Text>{playlist.published ? 'Published' : 'Draft'}</Text>
                    </Flex>
                  </Flex>
                ))
              )
            ) : (
              <LoadingPlaceholder multiple />
            )}
          </Flex>
        </Flex>
      </Box>
      <hr />
      <Box py={2} mb={3}>
        <Flex alignItems="center">
          <Flex flex={1}>
            <Heading>VIEW AND EDIT ALL TAGS</Heading>
          </Flex>
          <Box>
            <Flex>
              <Box mr={2}>
                <Button
                  variant="secondary"
                  as="a"
                  resolve={async () => {
                    const response = await request('admin/tags/publish', {
                      method: 'PATCH',
                      body: {
                        items: tags.data.results.filter(({ published }) => !published).map(({ id }) => id),
                        action: 'publish',
                      },
                    });

                    await tags.mutate();

                    if (matchError(response)) {
                      alert.onFailure('Failed to publish all tags.');
                    } else {
                      alert.onSuccess('Tags have been published successfully.');
                    }
                  }}
                >
                  Publish tags
                </Button>
              </Box>
              <Box>
                <Link to="/content/tags/add">
                  <Button variant="secondary" as="a">
                    Add Tag
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex width="600px" flexDirection="column">
          <Form
            onSubmit={({ search }) => {
              query.set('tagSearch', search || '');
              history.push(`${match.url}?${query.toString()}`);
            }}
            initialValues={{
              search: query.get('tagSearch') || '',
            }}
          >
            {({ handleSubmit }) => (
              <Flex flexDirection="row" as="form" onSubmit={handleSubmit} flex={0.4}>
                <Flex flex={1}>
                  <Box width={1}>
                    <Field component={Input} name="search" placeholder="Search by tag name" />
                  </Box>
                </Flex>
                <Flex>
                  <Box py={2} ml={3}>
                    <Button
                      css={css`
                        padding: 9px 16px;
                      `}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Form>
        </Flex>
        <Flex width={600} flexDirection="column">
          <Flex
            flexDirection="row"
            css={css`
              border-bottom: 2px solid black;
            `}
          >
            <Flex flexBasis="100px" p={2}></Flex>
            <Flex flex={2} p={2}>
              <Heading fontSize={2}>Tag</Heading>
            </Flex>
            <Flex flex={1} p={2}>
              <Heading fontSize={2}>Status</Heading>
            </Flex>
          </Flex>
          <Flex backgroundColor="#fff" flexDirection="column" maxHeight="320px" overflowY="auto">
            {tags.data ? (
              tags.data.count === 0 ? (
                <Text p={2}>No items have been found.</Text>
              ) : (
                tags.data.results.map((tag) => (
                  <Flex flexDirection="row" key={tag.id}>
                    <Flex flexBasis="100px" p={2}>
                      <Link to={`/content/tags/${tag.id}`}>
                        <IconButton>
                          <Img src={require('assets/pencil.svg')} alt="Edit Tag" />
                        </IconButton>
                      </Link>
                      <WithModal
                        text="Are you sure you want to delete this tag?"
                        renderButtons={({ close }) => (
                          <Flex>
                            <Box width={0.5}>
                              <Button variant="outline" onClick={close}>
                                Cancel
                              </Button>
                            </Box>
                            <Box width={0.5}>
                              <Button
                                variant="primary"
                                resolve={async () => {
                                  const response = await request(`/admin/tags/${tag.id}/`, {
                                    method: 'DELETE',
                                  });

                                  if (matchError(response)) {
                                    alert.onFailure(response.toString());
                                  } else {
                                    await tags.mutate();
                                  }
                                }}
                              >
                                Delete Tag
                              </Button>
                            </Box>
                          </Flex>
                        )}
                      >
                        {({ open }) => (
                          <Button
                            variant="nulled"
                            ml={2}
                            onClick={open}
                            css={css`
                              padding: 0;
                              height: 32px;
                              width: 32px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img
                              src={require('assets/delete.svg')}
                              css={css`
                                width: 32px;
                                height: 20px;
                              `}
                              alt="Delete Tag"
                            />
                          </Button>
                        )}
                      </WithModal>
                    </Flex>
                    <Flex flex={2} p={2}>
                      <Text>{tag.name}</Text>
                    </Flex>
                    <Flex flex={1} p={2}>
                      <Text>{tag.published ? 'Published' : 'Draft'}</Text>
                    </Flex>
                  </Flex>
                ))
              )
            ) : (
              <LoadingPlaceholder multiple />
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default React.memo(TracksMain);
