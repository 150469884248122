import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { Field, Form } from 'react-final-form';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';

import Button from 'components/Button';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Separator from 'components/Separator';

const UserProfileProducts = ({ match }) => {
  const user = useSelector((state) => state.users.data[match.params.pk], shallowEqual);
  const data = useSelector((state) => {
    return state.userProducts.keys[match.params.pk].data.map((k) => state.userProducts.data[k]);
  }, shallowEqual);

  const rowWidth = 1 / 9;

  if (!user) {
    return <LoadingPlaceholder />;
  }

  return (
    <>
      <Box variant="containerBox" p={3}>
        <Form
          onSubmit={() => {}}
          initialValues={{
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          }}
        >
          {() => (
            <form>
              <Flex mt={3}>
                <Box width={1 / 2} mr={2}>
                  <Field name="id" component={Input} label="ID" disabled />
                </Box>
                <Box width={1 / 2} ml={2}>
                  <Field name="email" component={Input} label="Email Address" disabled type="text" />
                </Box>
              </Flex>
              <Flex mt={3}>
                <Box width={1 / 2} mr={2}>
                  <Field
                    name="firstName"
                    component={Input}
                    label="First Name"
                    placeholder="Enter first name"
                    disabled
                  />
                </Box>
                <Box width={1 / 2} ml={2}>
                  <Field name="lastName" component={Input} label="Last Name" placeholder="Enter last name" disabled />
                </Box>
              </Flex>
            </form>
          )}
        </Form>
      </Box>
      <Box variant="containerBox" maxWidth={1200}>
        <Separator>Purchased Products</Separator>
        <Flex>
          <Box>
            <Link to={`${match.url}/add`}>
              <Button variant="nulled">
                <Text color="secondary">Add Product +</Text>
              </Button>
            </Link>
          </Box>
        </Flex>
        <Box mt={4}>
          <Flex>
            <Box variant="th" width={rowWidth}>
              Purchase
              <br />
              Date
            </Box>
            <Box variant="th" width={rowWidth}>
              Course
              <br />
              Name
            </Box>
            <Box variant="th" width={rowWidth}>
              Payment
              <br />
              Type
            </Box>
            <Box variant="th" width={rowWidth}>
              Amount
              <br />
              Paid
            </Box>
            <Box variant="th" width={rowWidth}>
              Amount
              <br />
              Refunded
            </Box>
            <Box variant="th" width={rowWidth}>
              Status
            </Box>
            <Box variant="th" width={rowWidth}>
              Discount
              <br />
              Code
            </Box>
            <Box variant="th" width={rowWidth}>
              Disabled
            </Box>
            <Box variant="th" width={rowWidth}>
              Disabled At
            </Box>
            <Box variant="th" width="80px" />
          </Flex>
          <Flex flexWrap="wrap">
            {data.map((product) => (
              <Flex variant="row" width={1} key={product.id}>
                <Box variant="tb" width={rowWidth}>
                  {product.startedAt ? format(new Date(product.startedAt), 'MM/dd/yyyy, HH:mm a') : 'Not started'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.product.shortName || product.product.name}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.paymentType || 'N/A'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  ${product.paidTotal / 100}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.amountRefunded ? `$${product.amountRefunded / 100}` : '–'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.statusText || '–'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.discountCode?.code || '–'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.disabled ? 'Yes' : 'No'}
                </Box>
                <Box variant="tb" width={rowWidth}>
                  {product.disabled && product.disabledAt
                    ? format(new Date(product.disabledAt), 'MM/dd/yyyy hh:mm a')
                    : '–'}
                </Box>
                <Box variant="tb" width="80px">
                  <Flex width={1} justifyContent="flex-end">
                    <Link to={`${match.url}/${product.id}`}>Edit</Link>
                  </Flex>
                </Box>
              </Flex>
            ))}
            {!data.length && (
              <Box width={1} my={5} mx={3}>
                <Text textAlign="center">User hasn't got any products</Text>
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </>
  );
};

UserProfileProducts.propTypes = {
  match: PropTypes.object.isRequired,
};

export default React.memo(UserProfileProducts);
