import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { Box, Text } from 'rebass';
import { compose } from 'redux';

import { USER_ROLE } from 'redux/enums';
import * as auth from 'redux/features/auth';

import Button from '../Button';
import * as Styled from './styles';

const Nav = ({ user, signout }) => {
  return (
    <Box
      css={css`
        height: 100%;
        overflow-y: scroll;

        a:hover {
          filter: brightness(0.9);
        }

        a:active {
          font-weight: bold;
        }

        &::-webkit-scrollbar {
          display: none;
        }
      `}
    >
      <Box
        width={1}
        sx={{
          padding: 3,
          borderBottom: '1px solid #000',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          alt={`${user.firstName} ${user.lastName}`}
          src={require('assets/user.svg')}
          css={css`
            width: 24px;
            height: 24px;
            margin-right: 8px;
          `}
        />
        <div>
          <Text
            color="#fff"
            fontSize={1}
            css={css`
              -webkit-line-clamp: 1;
            `}
          >
            {user.firstName} {user.lastName}
          </Text>
          <Text
            color="rgba(255,255,255,0.6)"
            fontSize={1}
            css={css`
              -webkit-line-clamp: 1;
            `}
          >
            {user.userType === USER_ROLE.ADMIN ? 'Admin' : 'Super Admin'}
          </Text>
        </div>
      </Box>
      <Box
        width={1}
        sx={{
          padding: 3,
          borderBottom: '1px solid #000',
        }}
      >
        <Text color="rgba(255,255,255,0.6)" fontSize={1} mt={1} mb={2}>
          Navigation
        </Text>
        <Styled.Link to="/users">
          <Box display="flex" p={2} alignItems="center">
            <Styled.IconContainer>
              <img
                alt="Users"
                src={require('assets/users.svg')}
                css={css`
                  width: 15px;
                  height: 14px;
                  object-fit: contain;
                `}
              />
            </Styled.IconContainer>
            <Text color="#fff">Users</Text>
          </Box>
        </Styled.Link>
        <Styled.Link to="/admins">
          <Box display="flex" p={2} alignItems="center">
            <Styled.IconContainer>
              <img
                alt="Users"
                src={require('assets/users.svg')}
                css={css`
                  width: 15px;
                  height: 14px;
                  object-fit: contain;
                `}
              />
            </Styled.IconContainer>
            <Text color="#fff">Admin Users</Text>
          </Box>
        </Styled.Link>
        <Styled.Link to="/payments/discounts" activeClassName="active" exact>
          <Box display="flex" p={2} alignItems="center">
            <Styled.IconContainer>
              <img
                alt="Payments"
                src={require('assets/creditcard.svg')}
                css={css`
                  width: 15px;
                  height: 10px;
                  object-fit: contain;
                `}
              />
            </Styled.IconContainer>
            <Text color="#fff">Discounts</Text>
          </Box>
        </Styled.Link>
      </Box>
      {user.userType === USER_ROLE.SUPER_ADMIN && (
        <>
          <Box
            width={1}
            sx={{
              padding: 3,
              borderBottom: '1px solid #000',
            }}
          >
            <Styled.Link to="/reports" activeClassName="active" exact>
              <Box display="flex" p={2} alignItems="center">
                <Styled.IconContainer>
                  <img
                    alt="Reports"
                    src={require('assets/reports.svg')}
                    css={css`
                      width: 16px;
                      height: 16px;
                      object-fit: contain;
                    `}
                  />
                </Styled.IconContainer>
                <Text color="#fff">Reports</Text>
              </Box>
            </Styled.Link>
            <Box ml="25px">
              <Styled.Link to="/reports/dashboard" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Dashboard
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/reports/sales" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Sales
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/reports/subscriptions" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Subscriptions
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/reports/courses" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Courses
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/course-completion" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Completion
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/reports/user-payments" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    User Payments
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
          </Box>
          <Box
            width={1}
            sx={{
              padding: 3,
              borderBottom: '1px solid #000',
            }}
          >
            <Styled.Link to="/content" activeClassName="active">
              <Box display="flex" p={2} alignItems="center">
                <Styled.IconContainer>
                  <img
                    alt="Quotes"
                    src={require('assets/photo.png')}
                    css={css`
                      width: 16px;
                      height: 16px;
                      object-fit: contain;
                    `}
                  />
                </Styled.IconContainer>
                <Text color="#fff">Content</Text>
              </Box>
            </Styled.Link>
            <Box ml="25px">
              <Styled.Link to="/content/quotes" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Quotes
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/content/photos" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Quote Photos
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/content/quizes-and-reflections" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Quizes & Reflections
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/content/track-images" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Audio Images
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/content/tracks" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Audio Library
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
            <Box ml="25px">
              <Styled.Link to="/content/playlists" activeClassName="active">
                <Box p={2}>
                  <Text color="#fff" opacity={0.6}>
                    Playlists
                  </Text>
                </Box>
              </Styled.Link>
            </Box>
          </Box>
        </>
      )}
      <Box display="flex" p={3} alignItems="center">
        <Button resolve={signout} variant="nulled">
          <Text color="#fff">Sign Out</Text>
        </Button>
      </Box>
    </Box>
  );
};

Nav.propTypes = {
  signout: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default compose(
  connect(
    (state) => ({
      user: state.auth.user,
    }),
    {
      signout: auth.signout,
    }
  ),
  React.memo
)(Nav);
