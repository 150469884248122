import styled from '@emotion/styled';

export const Line = styled.div`
  display: flex;
  border: 0.5px solid rgba(0, 0, 0, 0.09);
`;

export const PreviousButton = styled.img`
  width: 24px;
  height: 12px;
  object-fit: contain;
  object-position: left;
`;

export const NextButton = styled(PreviousButton)`
  transform: rotate(180deg);
  align-self: flex-end;
`;
