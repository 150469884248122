import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field, Form, FormSpy } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import Select from 'components/Select';
import Separator from 'components/Separator';
import useAlert from 'hooks/useAlert';
import { USER_ENTITY_STATUS } from 'redux/enums';
import * as coursesFeatures from 'redux/features/course';
import { errorToString } from 'utils/createFormError';

const CompletnessIcon = ({ completed }) => (
  <img
    alt={completed ? 'Complete' : 'Incomplete'}
    src={completed ? require('assets/complete-black.svg') : require('assets/incomplete-black.svg')}
    css={css`
      width: 14px;
      height: 14px;
    `}
  />
);

const SeparatorIcon = () => (
  <div
    css={css`
      margin: 0 16px;
      width: 1px;
      height: 20px;
      background: #000;
    `}
  />
);

const reduceCourseEntities = (courseEntities) => {
  const data = [];
  let lastChapter = {};
  let lastLesson = {};

  courseEntities.forEach((item) => {
    if (item.type === 'chapter') {
      if (lastChapter.id) {
        lastChapter.children.push(lastLesson);
        lastLesson = {
          children: [],
        };
        data.push(lastChapter);
        lastChapter.children = lastChapter.children.sort((a, b) => a.order - b.order);
      }

      lastChapter = {
        ...item,
        children: [],
      };
    } else if (item.type === 'lesson') {
      if (lastLesson.id) {
        lastChapter.children.push(lastLesson);
      }

      lastLesson = {
        ...item,
        children: [],
      };
    } else if (item.type === 'quiz') {
      lastLesson.children.push(item);
    }
  });

  if (lastLesson) {
    lastChapter.children.push(lastLesson);
  }

  lastChapter.children = lastChapter.children.sort((a, b) => a.order - b.order);
  data.push(lastChapter);

  return data;
};

const UserProfileCourses = ({ match }) => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses.items, shallowEqual);
  const alert = useAlert();

  const [courseId, setCourseId] = React.useState(courses[0].id);
  const [[courseEntities, nestedEntities], setCourseEntities] = React.useState([[], []]);

  React.useEffect(() => {
    dispatch(
      coursesFeatures.getEntities({
        course: courseId,
        user: match.params.pk,
      })
    ).then((action) => {
      if (!action.error) {
        setCourseEntities([action.payload, reduceCourseEntities(action.payload)]);
      } else {
        alert.onFailure(errorToString(action));
      }
    });
  }, [courseId]);

  const checkAllChecked = React.useCallback(
    (values) => {
      return Object.entries(values).reduce((pv, [key, v]) => {
        if (key.startsWith('field_')) {
          return v.selected && pv;
        }

        return pv;
      }, true);
    },
    [nestedEntities]
  );

  const getInitialValues = React.useCallback(() => {
    return courseEntities.reduce(
      (pv, cv) => ({
        ...pv,
        [`field_${cv.id}`]: {
          selected: false,
          completed: cv.status === USER_ENTITY_STATUS.COMPLETED,
        },
      }),
      {}
    );
  }, [courseEntities]);

  return (
    <>
      <Box variant="containerBox" p={3}>
        <Flex mt={3}>
          <Box width={1}>
            <Select
              input={{
                name: 'course',
                value: courseId,
                onChange: (e) => {
                  setCourseId(e.target.value);
                  setCourseEntities([[], []]);
                },
              }}
              label="Select Course"
              meta={{}}
            >
              {courses.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Box>
      <Form
        onSubmit={async (values) => {
          const body = courseEntities.reduce(
            (pv, cv) => ({
              ...pv,
              [cv.id]: values[`field_${cv.id}`].completed ? 'completed' : 'incomplete',
            }),
            {}
          );

          const action = await dispatch(
            coursesFeatures.patchEntities({
              body,
              course: courseId,
              user: match.params.pk,
            })
          );

          if (action.error) {
            alert.onFailure(errorToString(action));
          } else {
            setCourseEntities([action.payload, reduceCourseEntities(action.payload)]);
            alert.onSuccess('User has been updated successfully.');
          }
        }}
        initialValues={getInitialValues()}
        subscription={{
          pristine: true,
          dirty: true,
          dirtySinceLastSubmit: true,
          submitting: true,
        }}
      >
        {({ handleSubmit, form, pristine, dirty, dirtySinceLastSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Box variant="containerBox" maxWidth={800} p={3}>
              <Separator>Chapters</Separator>
              <Flex>
                <Box mr={3}>
                  <Button
                    variant="nulled"
                    onClick={() => {
                      form.batch(() => {
                        const { values } = form.getState();
                        courseEntities.forEach((entity) => {
                          const id = `field_${entity.id}`;

                          if (values[id].selected) {
                            form.change(id, {
                              selected: false,
                              completed: true,
                            });
                          }
                        });
                      });
                    }}
                  >
                    <Flex alignItems="center">
                      <Text color="secondary" mr={2}>
                        Mark as Complete
                      </Text>
                      <img
                        alt="Mark as Complete"
                        src={require('assets/complete.svg')}
                        css={css`
                          width: 20px;
                          height: 20px;
                        `}
                      />
                    </Flex>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="nulled"
                    onClick={() => {
                      form.batch(() => {
                        const { values } = form.getState();
                        courseEntities.forEach((entity) => {
                          const id = `field_${entity.id}`;

                          if (values[id].selected) {
                            form.change(id, {
                              selected: false,
                              completed: false,
                            });
                          }
                        });
                      });
                    }}
                  >
                    <Flex alignItems="center">
                      <Text color="secondary" mr={2}>
                        Mark as Incomplete
                      </Text>
                      <img
                        alt="Mark as Incomplete"
                        src={require('assets/incomplete.svg')}
                        css={css`
                          width: 20px;
                          height: 20px;
                        `}
                      />
                    </Flex>
                  </Button>
                </Box>
              </Flex>
              <Flex mt={2}>
                <Box mr={4}>
                  <Flex alignItems="center" ml={2}>
                    <FormSpy subscription={{ values: true }}>
                      {({ values }) => (
                        <CheckboxInput
                          input={{
                            checked: checkAllChecked(values),
                            onClick: () => {
                              const allSelected = checkAllChecked(values);

                              form.batch(() => {
                                courseEntities.forEach((item) => {
                                  const id = `field_${item.id}`;

                                  form.change(id, {
                                    ...values[id],
                                    selected: !allSelected,
                                  });
                                });
                              });
                            },
                          }}
                          meta={{}}
                          label="Select All Items"
                        />
                      )}
                    </FormSpy>
                  </Flex>
                </Box>
              </Flex>
              <Box
                width={1}
                css={css`
                  border-top: 2px solid #000;
                  border-bottom: 2px solid #000;
                `}
                mt={1}
              >
                {nestedEntities.length === 0 && <LoadingPlaceholder multiple />}
                {nestedEntities.map((chapter) => (
                  <Field name={`field_${chapter.id}`} key={chapter.id}>
                    {(chapterProps) => (
                      <Accordion
                        title={
                          <Flex flex="1" alignItems="center">
                            <CheckboxInput
                              {...chapterProps}
                              input={{
                                ...chapterProps.input,
                                checked: chapterProps.input.value.selected,
                                onChange: (v) => {
                                  const { checked } = v.target;
                                  chapterProps.input.onChange({
                                    ...chapterProps.input.value,
                                    selected: checked,
                                  });
                                },
                              }}
                              label={
                                <Text as="p">
                                  <strong>{`Chapter ${chapter.order}: `}</strong>
                                  {chapter.name}
                                </Text>
                              }
                              p={0}
                            />
                            <SeparatorIcon />
                            <CompletnessIcon completed={chapterProps.input.value.completed} />
                          </Flex>
                        }
                        css={css`
                          background-color: #dceafe;
                          padding: 0 8px;
                        `}
                        closedByDefault={!chapterProps.input.value.selected}
                      >
                        {chapter.children.map((lesson) => (
                          <Field name={`field_${lesson.id}`} key={lesson.id}>
                            {(lessonProps) => (
                              <Accordion
                                title={
                                  <Flex flex="1" alignItems="center">
                                    <CheckboxInput
                                      {...lessonProps}
                                      input={{
                                        ...lessonProps.input,
                                        checked: lessonProps.input.value.selected,
                                        onChange: (v) => {
                                          const { checked } = v.target;
                                          lessonProps.input.onChange({
                                            ...lessonProps.input.value,
                                            selected: checked,
                                          });
                                        },
                                      }}
                                      p={0}
                                      label={
                                        <Text ml={3} as="p">
                                          Lesson {lesson.order}: {lesson.name}
                                        </Text>
                                      }
                                    />
                                    <SeparatorIcon />
                                    <CompletnessIcon completed={lessonProps.input.value.completed} />
                                  </Flex>
                                }
                                css={css`
                                  background-color: #ebeff5;
                                  padding: 0 8px;
                                `}
                              >
                                {lesson.children.map((sublesson) => (
                                  <Field name={`field_${sublesson.id}`} key={sublesson.id}>
                                    {(itemProps) => (
                                      <Flex alignItems="center" flex="1" height={40} px={2}>
                                        <CheckboxInput
                                          {...itemProps}
                                          input={{
                                            ...itemProps.input,
                                            checked: itemProps.input.value.selected,
                                            onChange: (v) => {
                                              const { checked } = v.target;
                                              itemProps.input.onChange({
                                                ...itemProps.input.value,
                                                selected: checked,
                                              });
                                            },
                                          }}
                                          label={<Text ml={5}>{sublesson.name}</Text>}
                                        />
                                        <SeparatorIcon />
                                        <CompletnessIcon completed={itemProps.input.value.completed} />
                                      </Flex>
                                    )}
                                  </Field>
                                ))}
                              </Accordion>
                            )}
                          </Field>
                        ))}
                      </Accordion>
                    )}
                  </Field>
                ))}
              </Box>
              <Text mt={2} variant="caption">
                Usage note: select chapter, lesson or quiz and press the action button on the top of table. To save
                changes press a button &quot;Save Changes&quot;.
              </Text>
              <Flex mt={4} justifyContent="center">
                <Box width={1 / 2} pr={2}>
                  <Button variant="outline" onClick={form.reset} disabled={pristine}>
                    Reset
                  </Button>
                </Box>
                <Box width={1 / 2} pl={2}>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!dirty && !dirtySinceLastSubmit}
                    loading={submitting}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Flex>
            </Box>
          </form>
        )}
      </Form>
    </>
  );
};

UserProfileCourses.propTypes = {
  match: PropTypes.object.isRequired,
  courses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default React.memo(UserProfileCourses);
