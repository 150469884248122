import React from 'react';
import { css } from '@emotion/core';
import { format } from 'date-fns';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR from 'swr';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import request, { matchError } from 'utils/request';

const EditTrackImage = ({ match, history }) => {
  const alert = useAlert();

  const params = new URLSearchParams(history.location.search);

  if (!params.get('page')) {
    params.delete('page');
  }

  if (!params.get('limit')) {
    params.delete('limit');
  }

  const { data, isValidating, error, mutate } = useSWR(`/admin/images/${match.params.pk}/`);

  React.useEffect(() => {
    if (matchError(error)) {
      alert.onFailure(error.toString());
    }
  }, [alert, error]);

  if (!data && isValidating) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box width="100%" maxWidth={1400} mx="auto" p={3}>
      <DocumentTitle>{`Edit Image with ID ${data.id}`}</DocumentTitle>
      <Link to={`/content/track-images?${params.toString()}`}>
        <NavLink>BACK TO IMAGES</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Edit Image
      </Heading>
      <Form
        onSubmit={async ({ image, description }) => {
          const formData = new FormData();

          formData.append('description', description);

          if (image) {
            formData.append('image', image[0]);
          }

          const response = await request(`/admin/images/${data.id}/`, {
            method: 'PATCH',
            body: formData,
          });

          if (!matchError(response)) {
            await mutate(response.payload, false);
            alert.onSuccess('Image has been updated.');
          } else {
            return response.toFormError();
          }
        }}
        initialValues={{
          image: data.image,
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3} flexDirection="row">
              <Flex flex={0.4} mr={4}>
                <img
                  css={css`
                    width: 100%;
                    max-height: 650px;
                    object-fit: contain;
                    object-position: left top;
                  `}
                  alt="Attached"
                  src={data.image}
                />
              </Flex>
              <Flex flex={0.6} flexDirection="column">
                <Flex flexDirection="column">
                  <Box>
                    <Text>ID: {data.id}</Text>
                  </Box>
                  {data.createdAt && (
                    <Box>
                      <Text>Uploaded At: {format(new Date(data.createdAt), 'MMMM dd, yyyy')}</Text>
                    </Box>
                  )}
                  {data.width && data.height && (
                    <Box>
                      <Text>
                        Dimensions: {data.width} by {data.height} pixels
                      </Text>
                    </Box>
                  )}
                  {data.meta.format && (
                    <Box>
                      <Text>Format: {data.meta.format}</Text>
                    </Box>
                  )}
                  {data.meta.size && (
                    <Box>
                      <Text>Size: {data.meta.size / 1024} KB</Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={3}>
                  <Box width={1}>
                    <Field
                      name="image"
                      component={FileInput}
                      type="file"
                      accept="image/jpeg, image/gif, image/png"
                      multiple={false}
                      label="Replace Image"
                    />
                  </Box>
                </Flex>
                <FormError meta={meta} />
                <Flex mt={3}>
                  <Box width={1 / 2} mr={2}>
                    <Button
                      disabled={meta.pristine}
                      variant="outline"
                      onClick={() => {
                        form.reset();
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Box width={1 / 2} ml={2}>
                    <Button variant="primary" type="submit" loading={meta.submitting}>
                      Save Changes
                    </Button>
                  </Box>
                </Flex>
                <Flex mt={4} justifyContent="center">
                  <Box>
                    <WithModal
                      text="Are you sure you want to delete this image?"
                      renderButtons={({ close }) => (
                        <Flex>
                          <Box width={0.5}>
                            <Button variant="outline" onClick={close}>
                              Cancel
                            </Button>
                          </Box>
                          <Box width={0.5}>
                            <Button
                              variant="primary"
                              resolve={async () => {
                                const response = await request(`/admin/images/${match.params.pk}/`, {
                                  method: 'DELETE',
                                });

                                if (!matchError(response)) {
                                  history.push('/content/track-images');
                                  alert.onSuccess('Image has been deleted successfully.');
                                } else {
                                  close();
                                  setTimeout(() => alert.onFailure(response.toString()), 200);
                                }
                              }}
                            >
                              Delete Image
                            </Button>
                          </Box>
                        </Flex>
                      )}
                    >
                      {({ open }) => (
                        <Button variant="nulled" onClick={open}>
                          <Flex alignItems="center">
                            <Text color="secondary" mr={2}>
                              Delete Image
                            </Text>
                            <img
                              alt="Delete Image"
                              src={require('assets/delete.svg')}
                              css={css`
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                              `}
                            />
                          </Flex>
                        </Button>
                      )}
                    </WithModal>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default React.memo(EditTrackImage);
