import createFormError from 'utils/createFormError';

export class Result {
  constructor({ payload, status }) {
    this.payload = payload;
    this.status = status;
  }
}

export class FulfilledResult extends Result {}

export class RejectedResult extends Result {
  error = true;

  toError() {
    const { payload, error, status } = this;
    return {
      payload,
      error,
      status,
    };
  }

  toFormError() {
    return createFormError(this.toError());
  }

  toString() {
    return Object.values(this.toFormError()).join(' ');
  }
}
