export const USER_TYPE = [
  [1, 'Student'],
  [2, 'Corporate'],
  [3, 'Corporate admin'],
  [4, 'Course creator'],
  [6, 'Legacy'],
  [7, 'App reviewer'],
  [8, 'Admin'],
  [5, 'Super Admin'],
];

export const USER_TYPE_BY_KEY = USER_TYPE.reduce(
  (pv, [key, name]) => ({
    ...pv,
    [key]: name,
  }),
  {}
);

export const USER_ROLE = {
  ADMIN: 8,
  SUPER_ADMIN: 5,
};

export const USER_ENTITY_STATUS = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
};

export const USER_PRODUCT_ACCESS_TYPE = [
  [1, 'Discount'],
  [2, 'Complimentary'],
  [3, 'Paid Outside'],
];

export const QUESTION_TYPE = {
  CHOICE: 1,
  BOOL: 2,
  GAP: 3,
  SHORT_ANSWER: 4,
  LONG_ANSWER: 5,
};

export const QUESTION_TYPE_NAME = {
  [QUESTION_TYPE.CHOICE]: 'Multiple Choice',
  [QUESTION_TYPE.BOOL]: 'True or False',
  [QUESTION_TYPE.GAP]: 'Fill Gap',
  [QUESTION_TYPE.SHORT_ANSWER]: 'Short Answer',
  [QUESTION_TYPE.LONG_ANSWER]: 'Long Answer',
};

export const QUIZ_TYPE = {
  QUIZ: 1,
  REFLECTION: 2,
};

export const QUIZ_STATUS = {
  DRAFT: 1,
  PUBLISHED: 2,
};
