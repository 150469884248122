import React from 'react';
import { format } from 'date-fns';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import FormError from 'components/FormError';
import useAlert from 'hooks/useAlert';
import * as courseFeatures from 'redux/features/course';
import * as reportFeatures from 'redux/features/report';
import { getTokens } from 'utils/auth';
import { errorToString } from 'utils/createFormError';

const CoursesFilter = ({ resetPage }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const match = useRouteMatch();

  const [courses, setCourses] = React.useState([]);
  const [coursesSummary, setCoursesSummary] = React.useState({});

  const number = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
  });

  React.useEffect(() => {
    (async () => {
      const results = await Promise.all([
        dispatch(courseFeatures.list()),
        dispatch(
          reportFeatures.courseSummary({
            params: {
              date__gte: format(new Date(), 'yyyy-MM-dd'),
            },
          })
        ),
      ]);

      const errors = results.filter(({ error }) => error);

      if (errors.length) {
        alert.onFailure(errors.map((action) => errorToString(action)).join(' '));
      } else {
        setCourses(results[0].payload.results);
        setCoursesSummary(
          results[1].payload.results.reduce(
            (pv, cv) => ({
              ...pv,
              [cv.course]: cv,
            }),
            {}
          )
        );
      }
    })();
  }, []);

  const params = new URLSearchParams(history.location.search);

  return (
    <>
      <Form
        onSubmit={({ realEmailsOnly, ...courses }) => {
          resetPage();

          const newParams = new URLSearchParams();

          if (realEmailsOnly) {
            newParams.set('real_email_only', realEmailsOnly);
          }

          Object.keys(courses).forEach((key) => {
            if (courses[key]) {
              const id = key.split('course_')[1];
              newParams.append('completed_courses', id);
            }
          });

          history.push(`${match.url}?${newParams.toString()}`);
        }}
        initialValues={{
          realEmailsOnly: params.get('real_email_only') || false,
          ...courses.reduce((pv, cv) => {
            const param = params.getAll('completed_courses');
            const strId = cv.id.toString();

            return {
              ...pv,
              [`course_${cv.id}`]:
                (typeof param === 'string' && param === strId) || (Array.isArray(param) && param.includes(strId)),
            };
          }, {}),
        }}
        subscription={{
          ...FormError.formSubscription,
          values: true,
        }}
      >
        {({ handleSubmit, values, ...meta }) => (
          <Box as="form" onSubmit={handleSubmit} mb={2}>
            <Flex alignItems="center">
              <Flex flexDirection="column">
                <Text as="p">Courses:</Text>
                <Flex flex="1">
                  {courses.map(({ name, shortName, id }) => (
                    <Box mr={3} key={id}>
                      <Field
                        name={`course_${id}`}
                        component={CheckboxInput}
                        label={shortName || name}
                        type="checkbox"
                      />
                    </Box>
                  ))}
                </Flex>
                <Box mt={1}>
                  <Field name="realEmailsOnly" component={CheckboxInput} label="Hide unknown emails" type="checkbox" />
                </Box>
              </Flex>
              <Flex>
                <Button m={2} p={2} width="100px" height="42px" loading={meta.submitting} type="submit">
                  Apply
                </Button>
                <Button
                  m={2}
                  p={2}
                  width="160px"
                  height="42px"
                  onClick={() => {
                    const newParams = new URLSearchParams();
                    newParams.set('page', 1);
                    history.push(`${match.url}?${newParams.toString()}`);
                  }}
                >
                  Clear Filters
                </Button>
                <Button
                  m={2}
                  p={2}
                  width="160px"
                  height="42px"
                  resolve={async () => {
                    try {
                      const response = await fetch(process.env.REACT_APP_API_PATH + '/admin/users/download/', {
                        headers: {
                          Authorization: `JWT ${getTokens()[0]}`,
                        },
                      });

                      if (response.status === 200) {
                        const blob = await response.blob();
                        const a = document.createElement('a');

                        a.href = window.URL.createObjectURL(blob);
                        a.download = 'users list.csv';
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      } else {
                        alert.onFailure('API error has occured while downloading the list file.');
                      }
                    } catch {
                      alert.onFailure('Unknown error has occured while downloading the list file.');
                    }
                  }}
                >
                  Export as CSV
                </Button>
              </Flex>
            </Flex>
            <FormError meta={meta} />
          </Box>
        )}
      </Form>
      <Flex alignItems="center" justifyContent="space-around" p={3} backgroundColor="gray">
        {courses.map(({ id, shortName, name }) => (
          <Text as="span" key={id}>
            {shortName || name}: {number.format(coursesSummary[id]?.completed || 0)}
          </Text>
        ))}
      </Flex>
    </>
  );
};

export default React.memo(CoursesFilter);
