import theme from '@rebass/preset';

const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#000',
    error: '#DA1E28',
    secondary: '#0F62FE',
  },
  fonts: {
    ...theme.fonts,
    body: 'inherit',
  },
  fontWeights: {
    ...theme.fontWeights,
    heading: '600',
  },
  radii: {
    default: 0,
  },
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.primary,
      cursor: 'pointer',
      display: 'block',
      p: 3,
      borderRadius: 0,
      '&:hover': {
        filter: 'brightness(0.8)',
      },
    },
    nulled: {
      background: 'transparent',
      p: 0,
      textAlign: 'left',
      display: 'block',
      '&:hover': {
        opacity: 0.6,
      },
      cursor: 'pointer',
    },
    delete: {
      backgroundColor: 'error',
      color: '#fff',
      cursor: 'pointer',
    },
    outline: {
      p: 2,
      paddingTop: 14,
      paddingBottom: 14,
      color: '#000',
      background: '#fff',
      boxShadow: 'none',
      border: '2px solid #000',
      borderRadius: 0,
      '&:hover': {
        filter: 'brightness(0.95)',
      },
    },
  },
  variants: {
    ...theme.variants,
    authCard: {
      p: 4,
      bg: '#fff',
      boxShadow: 'card',
      borderRadius: 'default',
    },
    nav: {
      color: 'secondary',
    },
    containerBox: {
      width: '100%',
      maxWidth: 650,
      margin: '0 auto',
    },
    row: {
      mb: 1,
      '&:hover': {
        outline: '1.5px solid #0F62FE',
      },
    },
    th: {
      fontSize: 1,
      px: 3,
      py: 2,
      borderBottom: '1.5px solid #000',
      fontWeight: 'heading',
    },
    tb: {
      fontSize: 1,
      px: 3,
      py: 2,
      background: '#fff',
    },
    bt: {
      borderTop: '1px solid #000',
    },
    chart: {
      py: 3,
      borderTop: '1px solid #000',
    },
    border: {
      border: '1px solid #000',
    },
  },
  text: {
    ...theme.text,
    errorText: {
      fontSize: 0,
      color: 'error',
      py: 2,
    },
    caption: {
      fontSize: 0,
      letterSpacing: '0.01em',
      color: '#161616',
    },
    subtitle: {
      fontWeight: 'heading',
    },
  },
  forms: {
    input: {
      border: '1px solid rgba(0, 0, 0, 0.31)',
      background: '#fff',
      px: 3,
      py: 2,
    },
    inputError: {
      border: '1px solid rgba(0, 0, 0, 0.31)',
      borderColor: 'error',
      background: '#fff',
      px: 3,
      py: 2,
    },
  },
};

export default defaultTheme;
