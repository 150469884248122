import React from 'react';
import { css } from '@emotion/core';
import { Input } from '@rebass/forms';
import { Box } from 'rebass';

import FieldError from 'components/FieldError';
import TippedLabel from 'components/TippedLabel';

const FileInput = ({ input: { value, onChange, ...input }, tip, meta, label, ...props }) => {
  const fieldError = (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);

  return (
    <Box py={2}>
      {label && (
        <TippedLabel htmlFor={`${input.name}-input`} mb={2} alignItems="center" display="flex" tip={tip}>
          {label}
        </TippedLabel>
      )}
      <Input
        {...props}
        {...input}
        onChange={(e) => {
          onChange(Array.from(e.target.files));
        }}
        type="file"
        id={`${input.name}-input`}
        variant={fieldError ? 'inputError' : 'input'}
        css={css`
          :disabled {
            background-color: #f2f2f2;
          }
        `}
      />
      <FieldError meta={meta} />
    </Box>
  );
};

export default React.memo(FileInput);
