import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import useAlert from 'hooks/useAlert';
import request, { matchError } from 'utils/request';
import { checkRequired } from 'utils/validation';

const AddTrackImage = () => {
  const alert = useAlert();

  return (
    <Box variant="containerBox" p={3}>
      <DocumentTitle>Add New Image</DocumentTitle>
      <Link to="/content/track-images">
        <NavLink>BACK TO IMAGES</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Add Image
      </Heading>
      <Form
        onSubmit={async ({ image }, form) => {
          const formData = new FormData();

          formData.append('image', image[0]);

          const response = await request('/admin/images/', {
            method: 'POST',
            body: formData,
          });

          if (!matchError(response)) {
            alert.onSuccess('Image has been created.');
            setTimeout(form.reset);
          } else {
            return response.toFormError();
          }
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="image"
                  component={FileInput}
                  type="file"
                  accept="image/jpeg, image/gif, image/png"
                  multiple={false}
                  validate={checkRequired}
                  label="Select Image"
                />
              </Box>
            </Flex>
            <FormError meta={meta} />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Button
                  disabled={meta.pristine}
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Add Image
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default React.memo(AddTrackImage);
