import createRequest from 'redux/helpers/createRequest';
import * as types from 'redux/types/auth';
import { getTokens, removeTokens, setTokens } from 'utils/auth';

export const obtainToken = createRequest({
  type: 'token/obtain',
  url: 'token/obtain',
  method: 'post',
});

export const refreshToken = createRequest({
  type: 'token/refresh',
  url: 'token/refresh',
  method: 'post',
});

export const revokeToken = createRequest({
  type: 'token/revoke',
  url: 'token/revoke',
  method: 'post',
});

export const resetPassword = createRequest({
  type: 'password-reset',
  url: 'password-reset',
  method: 'post',
});

export const getMe = createRequest({
  type: 'user/me',
  url: 'users/me',
  method: 'get',
});

export const signout = () => (dispatch) => {
  removeTokens();
  dispatch({
    type: types.signout,
  });
};

export const checkAuth = () => async (dispatch, getState) => {
  const [access, refresh] = getTokens();

  if (access) {
    dispatch({
      type: types.setTokens,
      payload: {
        access,
        refresh,
      },
    });

    try {
      const result = await getMe()(dispatch, getState);

      if (result.error && result.meta.status === 401) {
        const refreshResult = await refreshToken({
          body: {
            refreshToken: refresh,
          },
        })(dispatch, getState);

        if (refreshResult.error) {
          dispatch(signout());
        } else {
          setTokens(refreshResult.payload);
        }
      }
    } catch {
      dispatch(signout());
    }
  }
};
