import React from 'react';

const LazyAccordion = React.lazy(() => import('./lazy'));

const Accordion = (props) => (
  <React.Suspense fallback={null}>
    <LazyAccordion {...props} />
  </React.Suspense>
);

export default React.memo(Accordion);
