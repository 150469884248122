import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Button } from 'rebass';

import Loader from '../Loader';

const ProxyButton = ({ children, resolve, onClick, loading, disabled, darkLoader, ...props }) => {
  const [resolveLoading, setResolveLoading] = React.useState(false);

  return (
    <Button
      width={1}
      onClick={async (event) => {
        if (resolve) {
          try {
            setResolveLoading(true);
            await resolve(event);
            setResolveLoading(false);
          } catch (e) {
            setResolveLoading(false);
            throw e;
          }
        } else if (onClick) {
          onClick(event);
        }
      }}
      type="button"
      disabled={disabled || loading}
      css={css`
        ${disabled && 'cursor: not-allowed;'}
      `}
      {...props}
    >
      {loading || resolveLoading ? <Loader black={darkLoader} /> : children}
    </Button>
  );
};

ProxyButton.propTypes = {
  children: PropTypes.node.isRequired,
  resolve: PropTypes.func,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(ProxyButton);
