const required = 'This field is required.';

export const checkRequired = (value) => (!value ? required : undefined);

export const checkNotNull = (value) => (!value || Number(value) < 1 ? required : undefined);

export const checkInt = (min) => (value) => {
  if (!value) {
    return required;
  }

  if (Number(value) > min) {
    return `Please, enter a value lower than ${min}.`;
  }

  if (Number(value) <= 0) {
    return 'Please, enter a positive value.';
  }
};

export const validatePassword = (value, { password }) => {
  if (!value) {
    return required;
  }

  if (value !== password) {
    return "Passwords don't match";
  }

  return undefined;
};

export const checkArrayRequired = (value) => (Array.isArray(value) && value.length > 0 ? undefined : required);

export const getFieldUiStatus = (value, meta) => ({
  focused: meta.active,
  filled: meta.active || meta.touched || !!value,
  valid: meta.valid && !meta.active,
  invalid: meta.submitError || (meta.invalid && meta.touched),
});
