import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Box, Flex, Text } from 'rebass';

import Button from '../Button';
import Modal from '../Modal';

const WithModal = ({ children, renderButtons, opened: defaultOpened, text, ...props }) => {
  const [opened, setOpened] = React.useState(!!defaultOpened);

  return (
    <>
      {children({ open: () => setOpened(true) })}
      <Modal
        isOpen={opened}
        onRequestClose={() => {
          setOpened(false);
        }}
        {...props}
      >
        <Box width={370} backgroundColor="#fff" onClick={(e) => e.stopPropagation()}>
          <Flex p={3} display="flex" flexDirection="column">
            <Flex justifyContent="flex-end">
              <Box>
                <Button variant="nulled" p={2} onClick={() => setOpened(false)}>
                  <img
                    alt="Close"
                    src={require('assets/close.svg')}
                    css={css`
                      width: 20px;
                      height: 20px;
                    `}
                  />
                </Button>
              </Box>
            </Flex>
            <Text my={4}>{text}</Text>
          </Flex>
          {renderButtons({ close: () => setOpened(false) })}
        </Box>
      </Modal>
    </>
  );
};

WithModal.propTypes = {
  children: PropTypes.func.isRequired,
  renderButtons: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default React.memo(WithModal);
