import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { Card, Heading, Text } from 'rebass';

import Button from 'components/Button';
import FormError from 'components/FormError';
import Input from 'components/Input';
import request, { matchError } from 'utils/request';
import { checkRequired } from 'utils/validation';

const EnterQR = () => {
  const [step, setStep] = React.useState(0);
  const [qr, setQr] = React.useState('');

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
      `}
    >
      <Card variant="authCard" width={1}>
        <Heading textAlign="center" mb={4}>
          Activate 2FA
        </Heading>
        <Text>
          It is required to activate a two-factor authentication to proceed. Please, download any authenticator
          application prior to the next step. For example,{' '}
          <a
            href="https://apps.apple.com/app/google-authenticator/id388497605"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Authenticator (iOS)
          </a>{' '}
          or{' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Authenticator (Android)
          </a>
          . The app will show the scanable QR only once. If the process fails, you will need to create an account once
          again.
        </Text>
        <Form
          onSubmit={async (body) => {
            if (step === 0) {
              const response = await request('/get_qr', {
                method: 'POST',
              });

              if (matchError(response)) {
                return response.toFormError();
              }

              setStep(1);
            } else if (step === 1) {
              const response = await request('/get_qr', {
                method: 'POST',
                body: {
                  code: body.code,
                },
              });

              if (matchError(response)) {
                return response.toFormError();
              }

              setQr(response.payload.qr);
              setStep(2);
            } else {
              window.location.reload();
            }
          }}
          subscription={FormError.formSubscriptions}
        >
          {({ handleSubmit, ...meta }) => (
            <form onSubmit={handleSubmit}>
              {step === 0 && (
                <>
                  <Heading fontSize={3} mt={4}>
                    Step 1
                  </Heading>
                  <Text my={3}>
                    Please, request the email verification code firstly. You should receive an email with a verification
                    code on your inbox afterwards.
                  </Text>
                  <FormError meta={meta} />
                  <Button type="submit" loading={meta.submitting}>
                    Send verification code
                  </Button>
                </>
              )}
              {step === 1 && (
                <>
                  <Heading fontSize={3} mt={4}>
                    Step 2
                  </Heading>
                  <Text my={3}>Notice: the QR code in the next step shows only once.</Text>
                  <Field
                    name="code"
                    component={Input}
                    type="number"
                    validate={checkRequired}
                    label="Enter the code from email"
                    placeholder="00000"
                  />
                  <FormError meta={meta} />
                  <Button loading={meta.submitting} type="submit" mt={3}>
                    Submit
                  </Button>
                </>
              )}
              {step === 2 && (
                <>
                  <Heading fontSize={3} mt={4}>
                    Step 3
                  </Heading>
                  <Text my={3}>Please, scan the QR code below. Notice: it shows only once.</Text>
                  <img
                    css={css`
                      width: 200px;
                      height: 200px;
                      margin: 0 auto;
                      display: block;
                    `}
                    alt="Scan QR code"
                    src={`data:image/svg+xml;base64,${qr}`}
                  />
                  <FormError meta={meta} />
                  <Button loading={meta.submitting} type="submit" mt={3}>
                    Done
                  </Button>
                </>
              )}
            </form>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default React.memo(EnterQR);
