import React from 'react';
import { css } from '@emotion/core';
import { Label } from '@rebass/forms';
import { Flex } from 'rebass';
import useSWR from 'swr';

import FieldError from 'components/FieldError';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { joinQuery, stringifyQuery } from 'utils/query';
import { matchError } from 'utils/request';

const TrackImageField = ({ meta, input: { onChange, value }, label }) => {
  const { data, isValidating, error } = useSWR(
    joinQuery(
      'admin/images',
      stringifyQuery({
        limit: 1000,
      })
    )
  );

  React.useEffect(() => {
    if (matchError(error)) {
      alert.onFailure(error.toString());
    }
  }, [alert, error]);

  return (
    <Flex mt={3} flexDirection="column">
      <Label mb={2}>{label}</Label>
      <Flex
        width={1}
        css={css`
          gap: 8px;
        `}
        flexWrap="wrap"
        maxHeight={548}
        overflowY="auto"
      >
        {data
          ? data.results.map((image) => (
              <Flex
                flexBasis={216}
                height={270}
                key={image.id}
                flexDirection="column"
                onClick={() => {
                  onChange(image.id);
                }}
                css={
                  value === image.id &&
                  css`
                    border: 3px solid blue;
                  `
                }
              >
                <img
                  css={css`
                    flex: 1;
                    object-fit: cover;
                    object-position: center;
                  `}
                  alt={`Preview for ${image.id}`}
                  src={image.image}
                />
              </Flex>
            ))
          : isValidating && <LoadingPlaceholder multiple />}
      </Flex>
      <FieldError meta={meta} />
    </Flex>
  );
};

export default React.memo(TrackImageField);
