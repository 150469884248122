import { camelizeKeys } from 'humps';

import { getTokens } from 'utils/auth';

import { FulfilledResult, RejectedResult } from './Result';
import {
  extendHeaders,
  formDataSerializer,
  isFormDataRequest,
  joinUrl,
  jsonSerializer,
  timeout,
  TIMEOUT_ERROR,
} from './utils';

async function requestFactory(endpoint, init = {}) {
  try {
    const response = await Promise.race([fetch(joinUrl(endpoint), init), timeout(init.timeout || 60000)]);
    let data;

    try {
      data = camelizeKeys(await response.json());
    } catch {}

    const { status, ok } = response;

    if (ok) {
      return new FulfilledResult({
        status,
        payload: data,
      });
    }

    return new RejectedResult({
      status,
      payload: data,
    });
  } catch (e) {
    return new RejectedResult({
      status: e instanceof Error && e.message === TIMEOUT_ERROR ? -1 : 0,
      payload: e,
    });
  }
}

export const matchError = (result) => result instanceof RejectedResult;

export const unwrapResult = (result) => {
  if (matchError(result)) {
    throw result;
  }

  return result;
};

export const matchApiError = (error) => !!error && !(error instanceof Error);

/**
 * JSON Request
 * @param endpoint - string
 * @param config - JsonRequestConfig
 * @returns Promise<R>
 */
export default async function request(endpoint, config = {}) {
  if (config.method) {
    config.method = config.method.toUpperCase();
  }

  const token = getTokens()[0];

  if (token) {
    config.headers = extendHeaders(config.headers, ['Authorization', `JWT ${token}`]);
  }

  const init = isFormDataRequest(config) ? formDataSerializer(config) : jsonSerializer(config);

  return requestFactory(endpoint, {
    ...init,
    body: init.body,
  });
}
