import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { format } from 'date-fns';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import * as photosFeatures from 'redux/features/photos';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

import { PhotosContext } from '../PhotosContext';

const Photo = ({ match, history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { results, refetch } = React.useContext(PhotosContext);

  const params = new URLSearchParams(history.location.search);

  if (!params.get('page')) {
    params.delete('page');
  }

  if (!params.get('limit')) {
    params.delete('limit');
  }

  const [photo, setPhoto] = React.useState();

  React.useEffect(() => {
    if (photo) {
      setPhoto(undefined);
    }

    dispatch(
      photosFeatures.get({
        pk: match.params.pk,
      })
    ).then((result) => {
      if (result.error) {
        alert.onFailure(errorToString(result));
      } else {
        setPhoto(result.payload);
      }
    });
  }, [match.params.pk]);

  React.useEffect(() => {
    refetch();
  }, []);

  const currentIndex = results.findIndex((p) => p.id === photo?.id);

  const previousId = results[currentIndex - 1]?.id;
  const nextId = results[currentIndex + 1]?.id;

  if (!photo) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box width="100%" maxWidth={1400} mx="auto" p={3}>
      <DocumentTitle>{`Edit Photo with ID ${photo.id}`}</DocumentTitle>
      <Link to={`/content/photos?${params.toString()}`}>
        <NavLink>BACK TO PHOTOS</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Edit Photo
      </Heading>
      <Form
        onSubmit={async ({ image, description }) => {
          const formData = new FormData();

          formData.append('description', description);

          if (image) {
            formData.append('image', image[0]);
          }

          const action = await dispatch(
            photosFeatures.patch({
              body: formData,
              pk: photo.id,
            })
          );

          if (!action.error) {
            alert.onSuccess('Photo has been updated.');
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          description: photo.description,
          image: photo.image,
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3} flexDirection="row">
              <Flex flex={0.4} mr={4}>
                <img
                  css={css`
                    width: 100%;
                    max-height: 650px;
                    object-fit: contain;
                    object-position: left top;
                  `}
                  alt="Attached"
                  src={photo.image}
                />
              </Flex>
              <Flex flex={0.6} flexDirection="column">
                <Flex flexDirection="column">
                  <Box>
                    <Text>ID: {photo.id}</Text>
                  </Box>
                  {photo.createdAt && (
                    <Box>
                      <Text>Uploaded At: {format(new Date(photo.createdAt), 'MMMM dd, yyyy')}</Text>
                    </Box>
                  )}
                  <Box>
                    <Text>
                      Dimensions: {photo.width} by {photo.height} pixels
                    </Text>
                  </Box>
                  {photo.meta.format && (
                    <Box>
                      <Text>Format: {photo.meta.format}</Text>
                    </Box>
                  )}
                  {photo.meta.size && (
                    <Box>
                      <Text>Size: {photo.meta.size / 1024} KB</Text>
                    </Box>
                  )}
                </Flex>
                <Flex mt={3}>
                  <Box width={1}>
                    <Field
                      name="description"
                      component={Input}
                      label="Photo Description"
                      placeholder="Enter Description"
                      validate={checkRequired}
                      as="textarea"
                      css={css`
                        height: 280px;
                      `}
                    />
                  </Box>
                </Flex>
                <Flex mt={3}>
                  <Box width={1}>
                    <Field
                      name="image"
                      component={FileInput}
                      type="file"
                      accept="image/jpeg, image/gif, image/png"
                      multiple={false}
                      label="Replace Image"
                    />
                  </Box>
                </Flex>
                <FormError meta={meta} />
                <Flex mt={3}>
                  <Box width={1 / 2} mr={2}>
                    <Button
                      disabled={meta.pristine}
                      variant="outline"
                      onClick={() => {
                        form.reset();
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Box width={1 / 2} ml={2}>
                    <Button variant="primary" type="submit" loading={meta.submitting}>
                      Save Changes
                    </Button>
                  </Box>
                </Flex>
                <Flex mt={4} justifyContent="center">
                  <Box>
                    <WithModal
                      text="Are you sure you want to delete this photo?"
                      renderButtons={({ close }) => (
                        <Flex>
                          <Box width={0.5}>
                            <Button variant="outline" onClick={close}>
                              Cancel
                            </Button>
                          </Box>
                          <Box width={0.5}>
                            <Button
                              variant="primary"
                              resolve={async () => {
                                dispatch(
                                  photosFeatures.del({
                                    pk: photo.id,
                                  })
                                ).then((action) => {
                                  if (!action.error) {
                                    history.push('/content/photos');
                                    alert.onSuccess('Photo has been deleted successfully.');
                                  } else {
                                    close();
                                    setTimeout(() => alert.onFailure(errorToString(action)), 200);
                                  }
                                });
                              }}
                            >
                              Delete Photo
                            </Button>
                          </Box>
                        </Flex>
                      )}
                    >
                      {({ open }) => (
                        <Button variant="nulled" onClick={open}>
                          <Flex alignItems="center">
                            <Text color="secondary" mr={2}>
                              Delete Photo
                            </Text>
                            <img
                              alt="Delete Photo"
                              src={require('assets/delete.svg')}
                              css={css`
                                width: 20px;
                                height: 20px;
                                object-fit: contain;
                              `}
                            />
                          </Flex>
                        </Button>
                      )}
                    </WithModal>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Form>
      <Flex flexDirection="row" mt={3}>
        <Flex flex={0.5}>
          {!!previousId && (
            <Link to={`/content/photos/${previousId}?${params.toString()}`}>
              <NavLink>Previous Image</NavLink>
            </Link>
          )}
        </Flex>
        <Flex flex={0.5} justifyContent="flex-end">
          {!!nextId && (
            <Link to={`/content/photos/${nextId}?${params.toString()}`}>
              <NavLink reversed>Next Image</NavLink>
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

Photo.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(Photo);
