import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Input } from '@rebass/forms';
import { Box } from 'rebass';

import FieldError from '../FieldError';
import TippedLabel from '../TippedLabel';

const ProxyInput = ({ label, input, meta, tip, ...props }) => {
  const fieldError = (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);

  return (
    <Box py={2}>
      {label && (
        <TippedLabel htmlFor={`${input.name}-input`} mb={2} alignItems="center" display="flex" tip={tip}>
          {label}
        </TippedLabel>
      )}
      <Input
        {...props}
        {...input}
        id={`${input.name}-input`}
        variant={fieldError ? 'inputError' : 'input'}
        css={css`
          :disabled {
            background-color: #f2f2f2;
          }
        `}
      />
      <FieldError meta={meta} />
    </Box>
  );
};

ProxyInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  tip: PropTypes.string,
};

export default React.memo(ProxyInput);
