import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import React from 'react';
import { css } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { Provider } from 'react-redux';
import { BrowserRouter, Link } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import { SWRConfig } from 'swr';

import { fetcher } from 'data/defaultFetcher';
import { matchError } from 'utils/request';

import Containers from './containers';
import store from './redux/createStore';
import theme from './styles/theme';

const App = () => (
  <SWRConfig
    value={{
      fetcher,
      onErrorRetry: (error, _, config, revalidate, opts) => {
        if (!matchError(error)) {
          return;
        }

        if (!config.isVisible()) {
          return;
        }

        if (typeof config.errorRetryCount === 'number' && opts.retryCount > config.errorRetryCount) {
          return;
        }

        if (error.status === -1 || error.status >= 500) {
          const count = Math.min(opts.retryCount, 8);
          const timeout = ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval;

          setTimeout(revalidate, timeout, opts);
        }
      },
    }}
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <main
            css={css`
              min-height: 100vh;
              display: flex;
              flex-direction: column;
              padding-top: 40px;
            `}
          >
            <Flex
              as="header"
              backgroundColor="#324755"
              py={2}
              px={3}
              height={40}
              css={css`
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 100;
              `}
            >
              <Link to="/">
                <Flex alignItems="center">
                  <img
                    alt="Dandapani Admin Panel"
                    src={require('assets/logo.svg')}
                    css={css`
                      object-fit: contain;
                      height: 24px;
                    `}
                  />
                  <Text ml={3} color="#fff" fontWeight="600">
                    Dandapani Admin Panel
                  </Text>
                </Flex>
              </Link>
            </Flex>
            <Containers />
          </main>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </SWRConfig>
);

export default App;
