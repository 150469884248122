import React from 'react';
import { Route, Switch } from 'react-router';

import AddPlaylist from './AddPlaylist';
import AddTag from './AddTag';
import AddTrack from './AddTrack';
import AddTrackImage from './AddTrackImage';
import EditTrackImage from './EditTrackImage';
import TrackImagesList from './TrackImagesList';
import TracksList from './TracksList';
import TracksMain from './TracksMain';

const TracksRouter = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}/track-images/add`} component={AddTrackImage} />
    <Route path={`${path}/track-images/:pk`} component={EditTrackImage} />
    <Route path={`${path}/track-images`} exact component={TrackImagesList} />
    <Route path={`${path}/playlists/add`} component={AddPlaylist} />
    <Route path={`${path}/playlists/:pk`} component={AddPlaylist} />
    <Route path={`${path}/playlists`} exact component={TracksMain} />
    <Route path={`${path}/tracks/add`} exact component={AddTrack} />
    <Route path={`${path}/tracks/:pk`} exact component={AddTrack} />
    <Route path={`${path}/tracks`} exact component={TracksList} />
    <Route path={`${path}/tags/add`} component={AddTag} />
    <Route path={`${path}/tags/:pk`} component={AddTag} />
  </Switch>
);

export default React.memo(TracksRouter);
