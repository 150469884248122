import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useDispatch } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';

import DocumentTitle from 'components/DocumentTitle';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import NavLink from 'components/NavLink';
import useAlert from 'hooks/useAlert';
import useUserRoute from 'hooks/useUserRoute';
import * as coursesFeatures from 'redux/features/course';
import * as userFeatures from 'redux/features/user';
import * as userProductsFeatures from 'redux/features/userProducts';
import { errorToString } from 'utils/createFormError';

import AddProduct from './components/AddProduct';
import EditProduct from './components/EditProduct';
import UserProfileCourses from './components/UserProfileCourses';
import UserProfileForm from './components/UserProfileForm';
import UserProfilePassword from './components/UserProfilePassword';
import UserProfileProducts from './components/UserProfileProducts';
import * as Styled from './styles';

const UserProfile = ({ match, history }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const alert = useAlert();

  const mounted = React.useRef();
  const [user, setUser] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  const loadingTrigger = history.location.pathname.split('/').length === 4;

  React.useEffect(() => {
    if (!loadingTrigger && mounted.current) {
      return;
    }

    mounted.current = true;

    Promise.all([
      dispatch(
        userFeatures.get({
          pk: match.params.pk,
        })
      ),
      dispatch(
        userProductsFeatures.list({
          params: {
            user: match.params.pk,
            started: true,
          },
        })
      ),
      dispatch(coursesFeatures.list()),
    ]).then((action) => {
      if (action.every(({ error }) => !error)) {
        setUser(action[0].payload);
        setLoaded(true);
      } else {
        alert.onFailure(
          action
            .filter(({ error }) => error)
            .map(errorToString)
            .join(' ')
        );
        history.push('/users');
      }
    });
  }, [loadingTrigger]);

  const [getRoute] = useUserRoute();

  if (!loaded) {
    return <LoadingPlaceholder />;
  }

  return (
    <>
      <DocumentTitle>{`${user.email ? `${user.firstName} ${user.lastName}` : 'Uknown'} Profile`}</DocumentTitle>
      <Switch>
        <Route path={`${match.path}/purchases/add`} component={AddProduct} />
        <Route path={`${match.path}/purchases/:userProduct`} component={EditProduct} />
        <Route
          path={match.path}
          render={() => (
            <>
              <Box variant="containerBox">
                <Link to={getRoute('search')}>
                  <NavLink>GO BACK TO USER SEARCH</NavLink>
                </Link>
                <Heading textAlign="center" my={5}>
                  View User
                </Heading>
                <Flex
                  css={css`
                    & > div .activeNavLink {
                      * {
                        color: ${theme.colors.secondary} !important;
                      }

                      ::after {
                        background-color: ${theme.colors.secondary} !important;
                      }
                    }
                  `}
                >
                  <Box width={1 / 4}>
                    <Styled.NavLink to={`${match.url}/profile`} activeClassName="activeNavLink">
                      <Box width={1} p={2}>
                        <Text textAlign="center" color="#525252">
                          Profile
                        </Text>
                      </Box>
                    </Styled.NavLink>
                  </Box>
                  <Box width={1 / 4}>
                    <Styled.NavLink to={`${match.url}/password`} activeClassName="activeNavLink">
                      <Box width={1} p={2}>
                        <Text textAlign="center" color="#525252">
                          Password
                        </Text>
                      </Box>
                    </Styled.NavLink>
                  </Box>
                  <Box width={1 / 4}>
                    <Styled.NavLink to={`${match.url}/purchases`} activeClassName="activeNavLink">
                      <Box width={1} p={2}>
                        <Text textAlign="center" color="#525252">
                          Purchases
                        </Text>
                      </Box>
                    </Styled.NavLink>
                  </Box>
                  <Box width={1 / 4}>
                    <Styled.NavLink to={`${match.url}/courses`} activeClassName="activeNavLink">
                      <Box width={1} p={2}>
                        <Text textAlign="center" color="#525252">
                          Courses
                        </Text>
                      </Box>
                    </Styled.NavLink>
                  </Box>
                </Flex>
              </Box>
              <Box width="1">
                <Switch>
                  <Route path={`${match.path}/profile`} component={UserProfileForm} />
                  <Route path={`${match.path}/password`} component={UserProfilePassword} />
                  <Route path={`${match.path}/purchases`} component={UserProfileProducts} />
                  <Route path={`${match.path}/courses`} component={UserProfileCourses} />
                  <Redirect to={`${match.url}/profile`} />
                </Switch>
              </Box>
            </>
          )}
        />
      </Switch>
    </>
  );
};

UserProfile.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(UserProfile);
