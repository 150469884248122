import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR, { mutate } from 'swr';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TableControls from 'components/TableControls';
import useAlert from 'hooks/useAlert';
import { errorToString } from 'utils/createFormError';
import { joinQuery, stringifyQuery } from 'utils/query';

const TrackImagesList = ({ history }) => {
  const alert = useAlert();

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = Number(params.get('page'));
    return pageParam ? pageParam - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = Number(params.get('limit'));
    return pageParam || 30;
  });

  const { data, isValidating, error } = useSWR(
    joinQuery(
      '/admin/images',
      stringifyQuery({
        limit,
        offset: page * limit,
      })
    )
  );

  const isLoading = !data && isValidating;

  useSWR(
    joinQuery(
      '/admin/images',
      stringifyQuery({
        limit,
        offset: (page + 1) * limit,
      })
    )
  );

  React.useEffect(() => {
    if (error) {
      alert.onFailure(errorToString(error));
    }
  }, [alert, error]);

  const controls = (
    <TableControls
      itemsPerPage={[15, 30, 90, 250]}
      page={page}
      limit={limit}
      count={data?.count || 0}
      setPage={setPage}
      setLimit={(l) => {
        setLimit(l);

        if (page * l > data.count) {
          setPage(Math.floor(data.count / l));
        }
      }}
    />
  );

  return (
    <Box variant="containerBox" position="relative" maxWidth={1160}>
      <DocumentTitle>Search Images</DocumentTitle>
      <Heading>CONTENT - AUDIO IMAGES</Heading>
      <Flex my={3} alignItems="center">
        <Flex flex={1}>
          <Heading fontSize={3}>View & Edit Images</Heading>
        </Flex>
        <Flex>
          <Box>
            <Link to="/content/track-images/add">
              <Button variant="secondary" as="a">
                Add Image
              </Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
      {data?.count > 0 && <Box mt={4}>{controls}</Box>}
      <Box mt={4}>
        <Flex
          flexWrap="wrap"
          css={css`
            margin: -8px;
          `}
        >
          {data
            ? data.results.map((image) => (
                <Flex
                  height={270}
                  width={216}
                  css={css`
                    box-sizing: content-box;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    &:hover > .editButton {
                      opacity: 1;
                      transition-timing-function: ease-in;
                    }
                  `}
                  p={2}
                  key={image.id}
                >
                  {image.image ? (
                    <img
                      alt={`For ${image.id}`}
                      src={image.image}
                      css={css`
                        flex: 1;
                        object-fit: cover;
                        object-position: center;
                      `}
                    />
                  ) : (
                    <Flex justifyContent="center" alignItems="center" flex={1} backgroundColor="gray">
                      <Text>No photo</Text>
                    </Flex>
                  )}
                  <Box
                    className="editButton"
                    css={css`
                      position: absolute;
                      bottom: 16px;
                      left: 32px;
                      right: 32px;
                      opacity: 0;
                      transition: 0.2s ease-out opacity;
                    `}
                  >
                    <Link
                      to={`/content/track-images/${image.id}?page=${params.get('page')}&limit=${params.get('limit')}`}
                      onClick={() => {
                        mutate(`/admin/images/${image.id}/`, image, false);
                      }}
                    >
                      <Button as="div">Edit</Button>
                    </Link>
                  </Box>
                </Flex>
              ))
            : isLoading && <LoadingPlaceholder multiple />}
        </Flex>
      </Box>
      {data && data.count
        ? controls
        : data &&
          data.results.length === 0 && (
            <Flex p={4} py={5} alignItems="center" justifyContent="center">
              <Text>No matching items found.</Text>
            </Flex>
          )}
    </Box>
  );
};

export default React.memo(TrackImagesList);
