import createRequest from 'redux/helpers/createRequest';

export const courseSummary = createRequest({
  type: 'reports/course-summary',
  url: 'reports/courses_summary',
  method: 'get',
});

export const courseProgress = createRequest({
  type: 'reports/course-progress',
  url: 'reports/courses_progress',
  method: 'get',
});

export const payments = createRequest({
  type: 'reports/payments',
  url: 'reports/payments',
  method: 'get',
});

export const paymentsSummary = createRequest({
  type: 'reports/payments-summary',
  url: 'reports/payments_summary',
  method: 'get',
});

export const downloadsSummary = createRequest({
  type: 'reports/downloads-summary',
  url: 'reports/app_downloads',
  method: 'get',
});
