import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DatepickerInput from 'components/DatepickerInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import NavLink from 'components/NavLink';
import Select from 'components/Select';
import useAlert from 'hooks/useAlert';
import useUserRoute from 'hooks/useUserRoute';
import { USER_PRODUCT_ACCESS_TYPE } from 'redux/enums';
import * as productFeatures from 'redux/features/product';
import * as userProducts from 'redux/features/userProducts';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

// free=true, free_type !== 1
const AddProduct = ({ match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      await dispatch(
        productFeatures.list({
          params: {
            limit: 100,
            offset: 0,
          },
        })
      );
    })();
  }, []);

  const user = useSelector((state) => state.users.data[match.params.pk], shallowEqual);
  const products = useSelector((state) => state.products.items, shallowEqual);

  const [getRoute] = useUserRoute();

  return (
    <Box variant="containerBox" p={3}>
      <Link to={getRoute(`${user.id}/purchases`)}>
        <NavLink>
          {`GO BACK TO USER ${user.firstName ? `${user.firstName} ${user.lastName}` : ''}`.toUpperCase()}
        </NavLink>
      </Link>
      <Heading textAlign="center" my={5}>
        Add Product
      </Heading>
      <Form
        onSubmit={async (body, form) => {
          const action = await dispatch(
            userProducts.post({
              body: {
                ...body,
                user: user.id,
                free: true,
              },
            })
          );

          if (!action.error) {
            alert.onSuccess('User Product has been created successfully.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        subscription={Form.formSubscriptions}
        initialValues={{
          freeType: USER_PRODUCT_ACCESS_TYPE[1][0],
        }}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1}>
                <Field name="product" component={Select} label="Select Product" validate={checkRequired}>
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.slug}
                    </option>
                  ))}
                </Field>
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field name="freeType" component={Select} label="Select Access Type" validate={checkRequired}>
                  {USER_PRODUCT_ACCESS_TYPE.filter(([id]) => id !== 1).map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="startedAt"
                  component={DatepickerInput}
                  label="Purchase Date"
                  showTimeSelect
                  timeIntervals={10}
                  timeFormat="HH:mm"
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  maxDate={new Date()}
                />
              </Box>
            </Flex>
            <Box width={1} mt={2}>
              <Field
                name="comment"
                component={Input}
                label="Notes"
                as="textarea"
                css={css`
                  height: 280px;
                `}
                tip={'Enter notes about this product. E.g. "Direct invoice using PayPal."'}
              />
            </Box>
            <FormError meta={meta} />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Button
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Save Changes
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

AddProduct.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(AddProduct);
