import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Label } from '@rebass/forms';
import ReactTooltip from 'react-tooltip';
import { Text } from 'rebass';

const TippedInput = ({ tip, children, htmlFor, ...props }) => (
  <Label htmlFor={htmlFor} {...props}>
    <Text mr={1}>{children}</Text>
    {tip && (
      <>
        <img
          data-tip={tip}
          data-for={`${htmlFor}-tooltip`}
          alt="Show Info"
          src={require('assets/info.svg')}
          css={css`
            width: 20px;
            height: 20px;
            margin-left: auto;
          `}
        />
        <ReactTooltip id={`${htmlFor}-tooltip`} place="top" effect="solid" multiline />
      </>
    )}
  </Label>
);

TippedInput.propTypes = {
  tip: PropTypes.string,
  children: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default React.memo(TippedInput);
