import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TableControls from 'components/TableControls';
import useAlert from 'hooks/useAlert';
import * as photosFeatures from 'redux/features/photos';
import { errorToString } from 'utils/createFormError';

import BulkUploadPhotos from './components/BulkUploadPhotos';

const PhotosList = ({ history, match }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [photosLoading, setPhotosLoading] = React.useState(true);

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = Number(params.get('page'));
    return pageParam ? pageParam - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = Number(params.get('limit'));
    return pageParam || 30;
  });

  const [data, setData] = React.useState({});

  React.useEffect(() => {
    params.set('limit', limit);
    params.set('page', page + 1);

    history.push(`${match.url}?${params.toString()}`);

    setPhotosLoading(true);

    dispatch(
      photosFeatures.list({
        params: {
          limit,
          offset: page * limit,
          q: params.get('search') || '',
        },
      })
    ).then((result) => {
      if (result.error) {
        alert.onFailure(errorToString(result));
      } else {
        setData({
          ...data,
          count: result.payload.count,
          [page]: result.payload.results,
        });
      }

      setPhotosLoading(false);
    });
  }, [page, limit, params.get('search')]);

  const controls = (
    <TableControls
      itemsPerPage={[15, 30, 90, 250]}
      page={page}
      limit={limit}
      count={data.count}
      setPage={setPage}
      setLimit={(l) => {
        setLimit(l);

        if (page * l > data.count) {
          setPage(Math.floor(data.count / l));
        }

        setData({
          count: data.count,
        });
      }}
    />
  );

  return (
    <Box variant="containerBox" position="relative" maxWidth={1160}>
      <DocumentTitle>Search Photos</DocumentTitle>
      <Heading>CONTENT - QUOTE PHOTOS</Heading>
      <BulkUploadPhotos />
      <Flex my={3} alignItems="center">
        <Flex flex={1}>
          <Heading fontSize={3}>View & Edit Photos</Heading>
        </Flex>
        <Flex>
          <Box>
            <Link to="/content/photos/add">
              <Button variant="secondary" as="a">
                Add Photo
              </Button>
            </Link>
          </Box>
        </Flex>
      </Flex>
      {data.count > 0 && <Box mt={4}>{controls}</Box>}
      <Box mt={4}>
        <Flex
          flexWrap="wrap"
          css={css`
            margin: -8px;
          `}
        >
          {data[page]
            ? data[page].map((photo) => (
                <Flex
                  height={270}
                  width={216}
                  css={css`
                    box-sizing: content-box;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    &:hover > .editButton {
                      opacity: 1;
                      transition-timing-function: ease-in;
                    }
                  `}
                  p={2}
                >
                  {photo.image ? (
                    <img
                      alt={`For ${photo.id}`}
                      src={photo.image}
                      css={css`
                        flex: 1;
                        object-fit: contain;
                        object-position: center;
                      `}
                    />
                  ) : (
                    <Flex justifyContent="center" alignItems="center" flex={1} backgroundColor="gray">
                      <Text>No photo</Text>
                    </Flex>
                  )}
                  <Box
                    className="editButton"
                    css={css`
                      position: absolute;
                      bottom: 16px;
                      left: 32px;
                      right: 32px;
                      opacity: 0;
                      transition: 0.2s ease-out opacity;
                    `}
                  >
                    <Link to={`/content/photos/${photo.id}?page=${params.get('page')}&limit=${params.get('limit')}`}>
                      <Button as="div">Edit</Button>
                    </Link>
                  </Box>
                </Flex>
              ))
            : photosLoading && <LoadingPlaceholder multiple />}
        </Flex>
      </Box>
      {data.count
        ? controls
        : !photosLoading && (
            <Flex p={4} py={5} alignItems="center" justifyContent="center">
              <Text>No matching items found.</Text>
            </Flex>
          )}
    </Box>
  );
};

PhotosList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default React.memo(PhotosList);
