import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field, Form, FormSpy } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass';

import Button from 'components/Button';
import DatepickerInput from 'components/DatepickerInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import Select from 'components/Select';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import useUserRoute from 'hooks/useUserRoute';
import { USER_ROLE, USER_TYPE } from 'redux/enums';
import * as userFeatures from 'redux/features/user';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const UserProfileForm = ({ match, history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [editable, setEditable] = React.useState(false);
  const userType = useSelector((state) => state.auth.user.userType);
  const user = useSelector((state) => state.users.data[match.params.pk], shallowEqual);

  const [getRoute] = useUserRoute();

  return (
    <Box variant="containerBox" p={3}>
      <Form
        onSubmit={async ({ id, country, ...body }) => {
          const action = await dispatch(
            userFeatures.patch({
              body,
              pk: user.id,
            })
          );

          if (!action.error) {
            alert.onSuccess('User has been updated.');
            setEditable(false);
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          dateJoined: new Date(user.dateJoined),
          comment: user.comment,
          userType: String(user.userType),
          country: user.country || '–',
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field name="id" component={Input} label="ID" disabled />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="email"
                  component={Input}
                  label="Email Address"
                  placeholder="Enter email address"
                  disabled={!editable}
                  validate={checkRequired}
                  type="text"
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field
                  name="firstName"
                  component={Input}
                  label="First Name"
                  placeholder="Enter first name"
                  disabled={!editable}
                  validate={checkRequired}
                />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="lastName"
                  component={Input}
                  label="Last Name"
                  placeholder="Enter last name"
                  disabled={!editable}
                  validate={checkRequired}
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field
                  name="userType"
                  component={Select}
                  label="User Type"
                  disabled={!editable}
                  validate={checkRequired}
                >
                  {USER_TYPE.filter((value) =>
                    userType === USER_ROLE.SUPER_ADMIN ? true : !value[1].includes('Admin')
                  ).map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="dateJoined"
                  component={DatepickerInput}
                  label="Date Joined"
                  disabled={!editable}
                  validate={checkRequired}
                  showTimeSelect
                  timeIntervals={10}
                  timeFormat="HH:mm"
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  maxDate={new Date()}
                  showNow
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} pr={2}>
                <Field name="country" component={Input} label="Country" disabled />
              </Box>
            </Flex>
            <Box width={1} mt={2}>
              <Field
                name="comment"
                component={Input}
                label="Notes"
                disabled={!editable}
                as="textarea"
                css={css`
                  height: 280px;
                `}
              />
            </Box>
            <FormError meta={meta} />
            {editable && (
              <Flex mt={3}>
                <Box width={1 / 2} mr={2}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setEditable(false);
                      form.reset();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => (
                    <WithModal
                      text={`You would grant the ${
                        values.userType === USER_ROLE.SUPER_ADMIN.toString() ? 'Super' : ''
                      } Admin permissions to this user. Are you sure?`}
                      renderButtons={({ close }) => (
                        <Flex>
                          <Box width={0.5}>
                            <Button variant="outline" onClick={close}>
                              Cancel
                            </Button>
                          </Box>
                          <Box width={0.5}>
                            <Button
                              variant="primary"
                              resolve={async () => {
                                close();
                                form.submit();
                              }}
                            >
                              Authorize
                            </Button>
                          </Box>
                        </Flex>
                      )}
                    >
                      {({ open }) => (
                        <Box width={1 / 2} ml={2}>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => {
                              const admins = [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN];
                              const newType = Number(values.userType);

                              if (admins.includes(newType) && user.userType !== newType) {
                                e.preventDefault();
                                open();
                              }
                            }}
                            loading={meta.submitting}
                          >
                            Save Changes
                          </Button>
                        </Box>
                      )}
                    </WithModal>
                  )}
                </FormSpy>
              </Flex>
            )}
          </form>
        )}
      </Form>
      {!editable && (
        <Flex alignItems="center" justifyContent="center" width={1} mt={3}>
          <Button variant="nulled" width="initial" onClick={() => setEditable(true)}>
            <Flex alignItems="center">
              <Text color="secondary" mr={2}>
                Edit Profile
              </Text>
              <img
                alt="Edit Profile"
                src={require('assets/pencil.svg')}
                css={css`
                  width: 14px;
                  height: 13px;
                  object-fit: contain;
                `}
              />
            </Flex>
          </Button>
        </Flex>
      )}
      <Flex mt={4} justifyContent="center">
        <Box>
          <WithModal
            text="Are you sure you want to delete this user?"
            renderButtons={({ close }) => (
              <Flex>
                <Box width={0.5}>
                  <Button variant="outline" onClick={close}>
                    Cancel
                  </Button>
                </Box>
                <Box width={0.5}>
                  <Button
                    variant="primary"
                    resolve={async () => {
                      dispatch(
                        userFeatures.del({
                          pk: user.id,
                        })
                      ).then((action) => {
                        if (!action.error) {
                          history.push(getRoute('search'));
                          alert.onSuccess('User has been deleted successfully.');
                        } else {
                          close();
                          setTimeout(() => alert.onFailure(errorToString(action)), 200);
                        }
                      });
                    }}
                  >
                    Delete User
                  </Button>
                </Box>
              </Flex>
            )}
          >
            {({ open }) => (
              <Button variant="nulled" onClick={open}>
                <Flex alignItems="center">
                  <Text color="secondary" mr={2}>
                    Delete User
                  </Text>
                  <img
                    alt="Delete User"
                    src={require('assets/delete.svg')}
                    css={css`
                      width: 20px;
                      height: 20px;
                      object-fit: contain;
                    `}
                  />
                </Flex>
              </Button>
            )}
          </WithModal>
        </Box>
      </Flex>
    </Box>
  );
};

UserProfileForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(UserProfileForm);
