import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'discount/get',
  url: 'admin/discount_codes',
  method: 'get',
});

export const get = createRequest({
  type: 'discount/get',
  url: ({ api, request }) => `${api}/admin/discount_codes/${request.pk}/`,
  method: 'get',
});

export const del = createRequest({
  type: 'discount/delete',
  url: ({ api, request }) => `${api}/admin/discount_codes/${request.pk}/`,
  method: 'delete',
});

export const patch = createRequest({
  type: 'discount/patch',
  url: ({ api, request }) => `${api}/admin/discount_codes/${request.pk}/`,
  method: 'patch',
});

export const post = createRequest({
  type: 'discount/post',
  url: 'admin/discount_codes',
  method: 'post',
});
