import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children, selector }) => {
  const portalRef = React.useRef();

  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    portalRef.current = document.querySelector(selector);
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, portalRef.current) : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  selector: PropTypes.string.isRequired,
};

export default React.memo(Portal);
