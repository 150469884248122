import React from 'react';
import { css } from '@emotion/core';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Box, Flex } from 'rebass';

import Button from 'components/Button';
import Input from 'components/Input';
import { QUESTION_TYPE } from 'redux/enums';
import { checkRequired } from 'utils/validation';

const checkBoolean = (value) => {
  if (!['True', 'False'].includes(value)) {
    return 'This field must have the "True" or "False" value.';
  }
};

const DroppableAnswers = ({ droppableId, name, question }) => {
  const form = useForm();

  React.useEffect(() => {
    if (question.answers) {
      const trueItems = question.answers.filter(({ isCorrect }) => isCorrect);

      if (!trueItems.length && question.answers[0]) {
        form.change(name + '.answers[0].isCorrect', true);
      }
    }
  }, [question.answers]);

  return (
    <Droppable droppableId={droppableId} type="answers">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <FieldArray name={name + '.answers'}>
            {({ fields }) =>
              fields.map((fieldName, index) => (
                <Draggable draggableId={fieldName} index={index} key={fieldName}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Flex
                        backgroundColor={index % 2 !== 0 ? '#eee' : '#fff'}
                        p={2}
                        flexDirection="row"
                        alignItems="center"
                      >
                        <img
                          alt="Reorder answers"
                          src={require('assets/drag.png')}
                          css={css`
                            width: 20px;
                            height: 20px;
                          `}
                        />
                        <Box mx={3}>
                          <Field name={fieldName + '.isCorrect'} type="checkbox" placeholder="Enter answer text">
                            {({ input }) => (
                              <input
                                {...input}
                                onChange={(e) => {
                                  if (
                                    question.answers &&
                                    question.answers.filter(({ isCorrect }) => isCorrect).length &&
                                    e.target.checked
                                  ) {
                                    const items = question.answers.map((item) => ({
                                      ...item,
                                      isCorrect: false,
                                    }));

                                    items[index].isCorrect = true;

                                    form.change(name + '.answers', items);
                                  } else {
                                    input.onChange(e);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </Box>
                        <Box
                          width={1}
                          css={css`
                            & > div:first-of-type {
                              padding: 0;
                            }

                            & > div:last-of-type {
                              padding-bottom: 0;
                            }
                          `}
                        >
                          <Field
                            name={fieldName + '.answer'}
                            component={Input}
                            validate={question.quizType === QUESTION_TYPE.BOOL ? checkBoolean : checkRequired}
                            placeholder="Enter answer text"
                          />
                          <Field name={fieldName + '.reason'} component={Input} placeholder="Enter example answer" />
                        </Box>
                        <Field name={fieldName}>
                          {({ input }) => (
                            <Box ml={3}>
                              <Button
                                variant="nulled"
                                onClick={() => {
                                  form.change(
                                    name + '.answers',
                                    question.answers.filter(({ id }) => id !== input.value.id)
                                  );
                                }}
                              >
                                <img
                                  alt="delete"
                                  src={require('assets/delete.svg')}
                                  css={css`
                                    width: 20px;
                                    height: 20px;
                                  `}
                                />
                              </Button>
                            </Box>
                          )}
                        </Field>
                      </Flex>
                    </div>
                  )}
                </Draggable>
              ))
            }
          </FieldArray>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default React.memo(DroppableAnswers);
