import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import { Field, Form, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import Button from 'components/Button';
import FormError from 'components/FormError';
import Input from 'components/Input';
import Select from 'components/Select';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import * as quotesFeatures from 'redux/features/quotes';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const Img = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
`;

const IconButton = styled(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditQuotes = ({ data, triggerUpdate }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const initialValues = React.useMemo(
    () =>
      Object.assign(
        {},
        ...data.map((quote) => ({
          [`${quote.id}_editing`]: false,
          [`${quote.id}_text`]: quote.text,
          [`${quote.id}_author`]: quote.author,
        }))
      ),
    [data]
  );

  return (
    <Form
      onSubmit={async (values, form) => {
        const patchData = data.reduce((pv, { id }) => {
          if (values[`${id}_editing`]) {
            pv.push(
              dispatch(
                quotesFeatures.patch({
                  body: {
                    text: values[`${id}_text`],
                    author: values[`${id}_author`],
                  },
                  pk: id,
                })
              )
            );
          }

          return pv;
        }, []);

        const results = await Promise.all(patchData);

        const errors = results
          .filter((action) => action.error)
          .reduce((pv, action) => {
            const formError = createFormError(action);
            return {
              ...pv,
              ...Object.keys(formError).reduce(
                (prev, next) => ({
                  ...prev,
                  [`${action.meta.pk}_${next}`]: formError[next],
                }),
                {}
              ),
            };
          }, {});

        if (Object.keys(errors).length) {
          return errors;
        }

        setTimeout(() => {
          form.batch(() => {
            data
              .filter(({ id }) => values[`${id}_editing`])
              .forEach(({ id }) => {
                form.change(`${id}_editing`, false);
              });
          });
        });
      }}
      initialValues={initialValues}
      subscription={FormError.formSubscriptions}
    >
      {({ handleSubmit, form, ...meta }) => (
        <form onSubmit={handleSubmit}>
          {data.map((quote) => (
            <Field name={`${quote.id}_editing`} key={quote.id}>
              {({ input }) => (
                <Flex variant="row" width={1}>
                  <Flex variant="tb" width={140}>
                    {input.value ? (
                      <IconButton
                        variant="nulled"
                        resolve={async () => {
                          const text = form.getFieldState(`${quote.id}_text`);
                          const author = form.getFieldState(`${quote.id}_author`);

                          if (!text.valid || !author.valid) {
                            return alert.onFailure('Please, correct errors in form.');
                          }

                          const action = await dispatch(
                            quotesFeatures.patch({
                              body: {
                                text: text.value,
                                author: author.value,
                              },
                              pk: quote.id,
                            })
                          );

                          if (!action.error) {
                            alert.onSuccess('Quote has been updated.');
                            form.change(input.name, false);
                          } else {
                            alert.onFailure(errorToString(action));
                          }
                        }}
                        darkLoader
                      >
                        <Img src={require('assets/complete.png')} alt="Save Quote" />
                      </IconButton>
                    ) : (
                      <IconButton
                        variant="nulled"
                        onClick={() => {
                          form.change(input.name, true);
                        }}
                      >
                        <Img src={require('assets/pencil.svg')} alt="Edit Quote" />
                      </IconButton>
                    )}
                    <WithModal
                      text="Are you sure you want to delete this quote?"
                      renderButtons={({ close }) => (
                        <Flex>
                          <Box width={0.5}>
                            <Button variant="outline" onClick={close}>
                              Cancel
                            </Button>
                          </Box>
                          <Box width={0.5}>
                            <Button
                              variant="primary"
                              resolve={() =>
                                dispatch(
                                  quotesFeatures.del({
                                    pk: quote.id,
                                  })
                                ).then((action) => {
                                  if (!action.error) {
                                    alert.onSuccess('Quote has been deleted successfully.');
                                    close();
                                    triggerUpdate();
                                  } else {
                                    close();
                                    setTimeout(() => alert.onFailure(errorToString(action)), 200);
                                  }
                                })
                              }
                            >
                              Delete Quote
                            </Button>
                          </Box>
                        </Flex>
                      )}
                    >
                      {({ open }) => (
                        <IconButton variant="nulled" onClick={open}>
                          <Img src={require('assets/delete.svg')} alt="Delete Quote" />
                        </IconButton>
                      )}
                    </WithModal>
                  </Flex>

                  <Box variant="tb" width={0.4}>
                    <Field name={`${quote.id}_author`} validate={checkRequired}>
                      {(props) =>
                        input.value ? (
                          <Select {...props}>
                            <option value="">Select author</option>
                            <option value="Dandapani">Dandapani</option>
                            <option value="Gurudeva Subramuniyaswami">Gurudeva Subramuniyaswami</option>
                          </Select>
                        ) : (
                          props.input.value
                        )
                      }
                    </Field>
                  </Box>

                  <Box variant="tb" width={1}>
                    <Field name={`${quote.id}_text`} validate={checkRequired}>
                      {(props) =>
                        input.value ? (
                          <Input
                            {...props}
                            as="textarea"
                            css={css`
                              height: 120px;
                            `}
                            placeholder="Enter text"
                          />
                        ) : (
                          props.input.value
                        )
                      }
                    </Field>
                  </Box>
                </Flex>
              )}
            </Field>
          ))}
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const atLeastOneOpened = data.map(({ id }) => values[`${id}_editing`]).includes(true);

              return (
                <>
                  <Prompt when={atLeastOneOpened} message="You have unsaved changes, are you sure you want to leave?" />
                  {atLeastOneOpened &&
                    ReactDOM.createPortal(
                      <Box mr={4}>
                        <Button
                          type="button"
                          css={css`
                            padding: 9px 16px;
                          `}
                          onClick={() => form.submit()}
                          loading={meta.submitting}
                        >
                          Save All
                        </Button>
                      </Box>,
                      document.getElementById('submit-portal')
                    )}
                </>
              );
            }}
          </FormSpy>
        </form>
      )}
    </Form>
  );
};

export default React.memo(EditQuotes);
