import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'photos/list',
  url: 'admin/photos',
  method: 'get',
});

export const get = createRequest({
  type: 'photos/get',
  url: ({ api, request }) => `${api}/admin/photos/${request.pk}/`,
  method: 'get',
});

export const del = createRequest({
  type: 'photos/delete',
  url: ({ api, request }) => `${api}/admin/photos/${request.pk}/`,
  method: 'delete',
});

export const patch = createRequest({
  type: 'photos/patch',
  url: ({ api, request }) => `${api}/admin/photos/${request.pk}/`,
  method: 'patch',
});

export const post = createRequest({
  type: 'photos/post',
  url: 'admin/photos',
  method: 'post',
});

export const bulkUpload = createRequest({
  type: 'photos/buldUpload',
  url: 'admin/photos/upload',
  method: 'post',
});
