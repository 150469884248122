import React from 'react';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Heading } from 'rebass';
import useSWR from 'swr';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import Input from 'components/Input';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import useAlert from 'hooks/useAlert';
import request, { matchError } from 'utils/request';
import { checkRequired } from 'utils/validation';

const AddTag = ({ match }) => {
  const alert = useAlert();
  const hasItem = match.params.pk;

  const tag = useSWR(hasItem ? `admin/tags/${match.params.pk}` : null);

  return (
    <Box variant="containerBox" p={3} maxWidth={696}>
      <DocumentTitle>{hasItem ? 'Edit' : 'Add New'} Tag</DocumentTitle>
      <Link to="/content/playlists">
        <NavLink>BACK TO PLAYLISTS</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        {hasItem ? 'Edit' : 'Add'} Tag
      </Heading>
      <Form
        onSubmit={async ({ name, published }, form) => {
          const response = await request(hasItem ? `/admin/tags/${hasItem}` : '/admin/tags/', {
            method: hasItem ? 'PATCH' : 'POST',
            body: {
              name,
              published,
            },
          });

          if (!matchError(response)) {
            alert.onSuccess(`Tag has been ${hasItem ? 'updated' : 'created'}.`);
            setTimeout(form.reset);
            tag.mutate(response.payload, false);
          } else {
            return response.toFormError();
          }
        }}
        subscription={Form.formSubscriptions}
        initialValues={{
          name: tag.data?.name,
          published: tag.data?.published,
        }}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="name"
                  component={Input}
                  validate={checkRequired}
                  label="Enter Name"
                  placeholder="Example Tag"
                />
              </Box>
            </Flex>
            <Flex mt={2}>
              <Box width={1}>
                <Field name="published" component={CheckboxInput} label="Published" type="checkbox" />
              </Box>
            </Flex>
            <FormError meta={meta} />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Button
                  disabled={meta.pristine}
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  {hasItem ? 'Edit' : 'Add'} Tag
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default React.memo(AddTag);
