import React from 'react';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR from 'swr';

import DocumentTitle from 'components/DocumentTitle';
import TableControls from 'components/TableControls';
import useUserRoute from 'hooks/useUserRoute';
import { USER_ROLE } from 'redux/enums';
import { joinQuery, stringifyQuery } from 'utils/query';

import CoursesFilter from './components/CoursesFilter';
import SearchFilter from './components/SearchFilter';
import UsersTable from './components/UsersTable';

const Users = ({ history }) => {
  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = params.get('page');
    return pageParam ? Number(pageParam) - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = params.get('limit');
    return pageParam ? Number(pageParam) : 10;
  });

  const [, baseRoute] = useUserRoute();

  const keyGenerator = (page) => {
    const body = {};

    if (baseRoute === 'admins') {
      body.user_type__in = `${USER_ROLE.ADMIN},${USER_ROLE.SUPER_ADMIN}`;
    }

    if (baseRoute === 'course-completion') {
      if (params.has('real_email_only')) {
        body.real_email_only = params.get('real_email_only');
      }

      if (params.has('completed_courses')) {
        body.completed_courses = params.getAll('completed_courses');
      }
    }

    if (baseRoute === 'users') {
      body.q = params.get('search') || '';
    }

    return joinQuery(
      'admin/users',
      stringifyQuery({
        ...body,
        limit,
        offset: page * limit,
      })
    );
  };

  const { data, isValidating } = useSWR(keyGenerator(page));
  useSWR(keyGenerator(page + 1));

  const loading = !data && isValidating;

  const { data: countData } = useSWR('admin/users?limit=0');
  const totalUsers = countData?.count || 0;

  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 });

  return (
    <Box variant="containerBox" position="relative" maxWidth={900}>
      {['users', 'admins'].includes(baseRoute) ? (
        <>
          <DocumentTitle>Search Users</DocumentTitle>
          <Flex justifyContent="center">
            <Heading textAlign="center" mb={3}>
              Search Users
            </Heading>
          </Flex>
          <SearchFilter
            resetPage={() => {
              setPage(0);
            }}
          />
        </>
      ) : (
        <>
          <DocumentTitle>User Course Completion</DocumentTitle>
          <Flex justifyContent="center">
            <Heading textAlign="center" mb={3}>
              User Course Completion
            </Heading>
          </Flex>
          <CoursesFilter
            resetPage={() => {
              setPage(0);
            }}
          />
        </>
      )}
      {!!totalUsers && (
        <Box mt={2}>
          <Text>Total users: {formatter.format(totalUsers)}</Text>
        </Box>
      )}
      <UsersTable data={data?.results} loading={loading} coursesCompletion={baseRoute === 'course-completion'} />
      {data?.count ? (
        <TableControls
          page={page}
          limit={limit}
          count={data.count}
          setPage={setPage}
          setLimit={(l) => {
            setLimit(l);

            if (page * l > data?.count) {
              setPage(Math.floor(data?.count / l));
            }
          }}
        />
      ) : (
        !loading && (
          <Flex p={4} py={5} alignItems="center" justifyContent="center">
            <Text>No matching items found.</Text>
          </Flex>
        )
      )}
    </Box>
  );
};

export default React.memo(Users);
