import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Label } from '@rebass/forms';
import { Box, Flex, Text } from 'rebass';

import FieldError from '../FieldError';

const ProxyCheckbox = ({ label, input: { value, ...input }, meta, p, ...props }) => {
  const fieldError = (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);

  return (
    <Box py={2} p={p}>
      <Flex>
        <Label htmlFor={`${input.name}-input`} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            {...props}
            {...input}
            id={`${input.name}-input`}
            variant={fieldError ? 'checkboxError' : 'checkbox'}
          />
          <Text ml={2} as="p">
            {label}
          </Text>
        </Label>
      </Flex>
      <FieldError meta={meta} />
    </Box>
  );
};

ProxyCheckbox.propTypes = {
  label: PropTypes.any,
  input: PropTypes.object,
  meta: PropTypes.object,
  p: PropTypes.any,
};

export default React.memo(ProxyCheckbox);
