export const initialState = {
  loaded: false,
  loading: false,
  error: null,
};

const reducerCreator = (typeCreator) => (state = initialState, action) => {
  if (action.type === typeCreator.request) {
    return {
      loading: true,
      loaded: false,
      data: state.data,
      error: null,
    };
  }

  if (action.type === typeCreator.success) {
    return {
      loading: false,
      loaded: true,
      data: action.payload,
      error: null,
      status: action.meta.status,
    };
  }

  if (action.type === typeCreator.failure) {
    return {
      loading: false,
      loaded: false,
      error: action.payload,
      status: action.meta.status,
    };
  }

  return state;
};

export default (state = {}, action) => {
  const api = action.meta?.['@@api'];

  if (api) {
    return {
      ...state,
      [api.reducer]: reducerCreator(api)(state[api.reducer], action),
    };
  }

  return state;
};
