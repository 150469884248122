import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR from 'swr';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TableControls from 'components/TableControls';
import useAlert from 'hooks/useAlert';
import { errorToString } from 'utils/createFormError';
import { joinQuery, stringifyQuery } from 'utils/query';
import request, { matchError } from 'utils/request';

const TracksList = ({ history, match }) => {
  const alert = useAlert();

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = Number(params.get('page'));
    return pageParam ? pageParam - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = Number(params.get('limit'));
    return pageParam || 30;
  });

  const { data, isValidating, error, mutate } = useSWR(
    joinQuery(
      '/admin/tracks',
      stringifyQuery({
        limit,
        offset: page * limit,
      })
    )
  );

  const isLoading = !data && isValidating;

  useSWR(
    joinQuery(
      '/admin/tracks',
      stringifyQuery({
        limit,
        offset: (page + 1) * limit,
      })
    )
  );

  React.useEffect(() => {
    if (error) {
      alert.onFailure(errorToString(error));
    }
  }, [alert, error]);

  return (
    <Box variant="containerBox" position="relative" maxWidth={1200}>
      <DocumentTitle>Search Tracks</DocumentTitle>
      <Heading>CONTENT - AUDIO LIBRARY</Heading>
      <Box>
        <Heading fontSize={3}>VIEW AND EDIT ALL TRACKS</Heading>
      </Box>
      <Flex flexDirection="row" mt={3} alignItems="center">
        <Form
          onSubmit={({ search }) => {
            setPage(0);
            params.set('search', search || '');
            history.push(`${match.url}?${params.toString()}`);
          }}
          initialValues={{
            search: params.get('search') || '',
          }}
        >
          {({ handleSubmit }) => (
            <Flex flexDirection="row" as="form" onSubmit={handleSubmit} flex={0.5}>
              <Flex flex={1}>
                <Box width={1}>
                  <Field component={Input} name="search" placeholder="Search by track name" />
                </Box>
              </Flex>
              <Flex>
                <Box py={2} ml={3}>
                  <Button
                    css={css`
                      padding: 9px 16px;
                    `}
                    type="submit"
                  >
                    Search
                  </Button>
                </Box>
              </Flex>
            </Flex>
          )}
        </Form>
        <Box ml="auto">
          <Flex>
            <Box mr={2}>
              <Button
                variant="secondary"
                as="a"
                resolve={async () => {
                  const response = await request('admin/tracks/publish', {
                    method: 'PATCH',
                    body: {
                      action: 'publish',
                      items: data.results.filter(({ published }) => !published).map(({ id }) => id),
                    },
                  });

                  await mutate();

                  if (matchError(response)) {
                    alert.onFailure('Failed to publish all tracks.');
                  } else {
                    alert.onSuccess('Tracks have been published successfully.');
                  }
                }}
              >
                Publish tracks on page
              </Button>
            </Box>
            <Box>
              <Link to="/content/tracks/add">
                <Button variant="secondary" as="a">
                  Add Track
                </Button>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box mt={2}>
        <Flex>
          <Box variant="th" width={100}>
            ID
          </Box>
          <Box variant="th" flex={1}>
            Track Name
          </Box>
          <Box variant="th" flex={1}>
            Playlists
          </Box>
          <Box variant="th" width={120}>
            Plays
          </Box>
          <Box variant="th" width={120}>
            Status
          </Box>
        </Flex>
        <Flex flexDirection="column" backgroundColor="#fff">
          {data?.results
            ? data.results.map((track) => (
                <Flex key={track.id}>
                  <Box variant="tb" width={100}>
                    <Link to={`${match.url}/${track.id}`}>{track.id}</Link>
                  </Box>
                  <Box variant="tb" flex={1}>
                    {track.name}
                  </Box>
                  <Box variant="tb" flex={1}>
                    {track.playlists.map(({ name }) => name).join(', ')}
                  </Box>
                  <Box variant="tb" width={120}>
                    {track.played}
                  </Box>
                  <Box variant="tb" width={120}>
                    {track.published ? 'Published' : 'Draft'}
                  </Box>
                </Flex>
              ))
            : isLoading && <LoadingPlaceholder />}
        </Flex>
      </Box>
      {data?.count ? (
        <TableControls
          page={page}
          limit={limit}
          count={data.count}
          setPage={(page) => {
            setPage(page);
          }}
          setLimit={(l) => {
            setLimit(l);

            if (page * l > data.count) {
              setPage(Math.floor(data.count / l));
            }
          }}
        />
      ) : (
        !isLoading && (
          <Flex p={4} py={5} alignItems="center" justifyContent="center">
            <Text>No matching items found.</Text>
          </Flex>
        )
      )}
    </Box>
  );
};

export default React.memo(TracksList);
