import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Redirect, Route, Switch } from 'react-router-dom';

import SignIn from './components/SignIn';
// import ForgotPassword from './components/ForgotPassword';

const Auth = ({ match }) => (
  <div
    css={css`
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
    `}
  >
    <Switch>
      <Route path={`${match.path}/signin`} component={SignIn} />
      {/* <Route path={`${match.path}/forgot-password`} component={ForgotPassword} /> */}
      <Redirect to={`${match.path}/signin`} />
    </Switch>
  </div>
);

Auth.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default React.memo(Auth);
