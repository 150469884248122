import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'user-products/list',
  url: 'admin/user_products',
  method: 'get',
});

export const get = createRequest({
  type: 'user-products/get',
  url: ({ api, request }) => `${api}/admin/user_products/${request.pk}/`,
  method: 'get',
});

export const del = createRequest({
  type: 'user-products/delete',
  url: ({ api, request }) => `${api}/admin/user_products/${request.pk}/`,
  method: 'delete',
});

export const patch = createRequest({
  type: 'user-products/patch',
  url: ({ api, request }) => `${api}/admin/user_products/${request.pk}/`,
  method: 'patch',
});

export const post = createRequest({
  type: 'user-products/post',
  url: 'admin/user_products',
  method: 'post',
});

export const disable = createRequest({
  type: 'user-products/disable',
  url: ({ api, request }) => `${api}/admin/user_products/${request.pk}/disable/`,
  method: 'patch',
});

export const enable = createRequest({
  type: 'user-products/enable',
  url: ({ api, request }) => `${api}/admin/user_products/${request.pk}/enable/`,
  method: 'patch',
});
