import createRequest from 'redux/helpers/createRequest';

export const list = createRequest({
  type: 'courses/get',
  url: 'admin/courses',
  method: 'get',
});

export const getEntities = createRequest({
  type: 'course-entities/get',
  url: ({ api, request }) => `${api}/admin/courses/${request.course}/entities/${request.user}/`,
  method: 'get',
});

export const patchEntities = createRequest({
  type: 'course-entities/patch',
  url: ({ api, request }) => `${api}/admin/courses/${request.course}/entities/${request.user}/`,
  method: 'patch',
});

export const getChapters = createRequest({
  type: 'chapters/get',
  url: 'admin/chapters',
  method: 'get',
});

export const getLessons = createRequest({
  type: 'lessons/get',
  url: 'admin/lessons',
  method: 'get',
});

export const getQuestions = createRequest({
  type: 'questions/get',
  url: 'admin/quiz_questions',
  method: 'get',
});

export const postQuestions = createRequest({
  type: 'questions/post',
  url: 'admin/quiz_questions',
  method: 'post',
});

export const patchQuestions = createRequest({
  type: 'questions/patch',
  url: ({ api, request }) => `${api}/admin/quiz_questions/${request.id}/`,
  method: 'patch',
});

export const deleteQuestions = createRequest({
  type: 'questions/delete',
  url: ({ api, request }) => `${api}/admin/quiz_questions/${request.id}/`,
  method: 'delete',
});

export const getAnswers = createRequest({
  type: 'answers/get',
  url: 'admin/quiz_question_answers',
  method: 'get',
});

export const postAnswers = createRequest({
  type: 'answers/post',
  url: 'admin/quiz_question_answers',
  method: 'post',
});

export const patchAnswers = createRequest({
  type: 'answers/patch',
  url: ({ api, request }) => `${api}/admin/quiz_question_answers/${request.id}/`,
  method: 'patch',
});

export const deleteAnswers = createRequest({
  type: 'answers/delete',
  url: ({ api, request }) => `${api}/admin/quiz_question_answers/${request.id}/`,
  method: 'delete',
});

export const getQuizzes = createRequest({
  type: 'quizzes/get',
  url: 'admin/quizzes',
  method: 'get',
});

export const postQuiz = createRequest({
  type: 'quizzes/post',
  url: 'admin/quizzes',
  method: 'post',
});

export const patchQuiz = createRequest({
  type: 'quizzes/patch',
  url: ({ api, request }) => `${api}/admin/quizzes/${request.id}/`,
  method: 'patch',
});

export const deleteQuiz = createRequest({
  type: 'quizzes/delete',
  url: ({ api, request }) => `${api}/admin/quizzes/${request.id}/`,
  method: 'delete',
});
