import React from 'react';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import useAlert from 'hooks/useAlert';
import * as photosFeatures from 'redux/features/photos';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const AddPhoto = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  return (
    <Box variant="containerBox" p={3}>
      <DocumentTitle>Add New Photo</DocumentTitle>
      <Link to="/content/photos">
        <NavLink>BACK TO PHOTOS</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Add Photo
      </Heading>
      <Form
        onSubmit={async ({ description, image }, form) => {
          const formData = new FormData();

          formData.append('description', description);
          formData.append('image', image[0]);

          const action = await dispatch(
            photosFeatures.post({
              body: formData,
            })
          );

          if (!action.error) {
            alert.onSuccess('Photo has been created.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="description"
                  component={Input}
                  label="Photo Description"
                  placeholder="Enter Description"
                  validate={checkRequired}
                  as="textarea"
                  css={css`
                    height: 280px;
                  `}
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="image"
                  component={FileInput}
                  type="file"
                  accept="image/jpeg, image/gif, image/png"
                  multiple={false}
                  validate={checkRequired}
                  label="Select Image"
                />
              </Box>
            </Flex>
            <FormError meta={meta} />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Button
                  disabled={meta.pristine}
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Add Photo
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default React.memo(AddPhoto);
