import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import LoadingPlaceholder from 'components/LoadingPlaceholder';
import useUserRoute from 'hooks/useUserRoute';
import { USER_TYPE_BY_KEY } from 'redux/enums';

const getCourses = (user) => {
  let courses = [];

  if (user.completedFm) {
    courses.push('FM');
  }

  if (user.completedUf) {
    courses.push('UF');
  }

  if (user.completedItm) {
    courses.push('ITM');
  }

  if (user.completedEa1) {
    courses.push('EA1');
  }

  return courses.join(', ');
};

const UsersTable = ({ data, loading, coursesCompletion }) => {
  const [getRoute] = useUserRoute();

  return (
    <Box mt={4}>
      <Flex>
        <Box variant="th" width={100}>
          ID
        </Box>
        <Box variant="th" width={0.45}>
          Email
        </Box>
        <Box variant="th" width={0.2}>
          First Name
        </Box>
        <Box variant="th" width={0.2}>
          Last Name
        </Box>
        <Box variant="th" width={0.15}>
          {coursesCompletion ? 'Course' : 'User Type'}
        </Box>
      </Flex>
      <Flex flexWrap="wrap">
        {data
          ? data.map((user) => (
              <Flex variant="row" width={1} key={user.id}>
                <Box variant="tb" width={100}>
                  <Link to={getRoute(`${user.id}/profile`)}>{user.id}</Link>
                </Box>
                <Box variant="tb" width={0.45}>
                  {user.email || user.key}
                </Box>
                <Box variant="tb" width={0.2}>
                  {user.firstName || 'Unknown iOS User'}
                </Box>
                <Box variant="tb" width={0.2}>
                  {user.lastName}
                </Box>
                <Box variant="tb" width={0.15}>
                  {coursesCompletion ? getCourses(user) : USER_TYPE_BY_KEY[user.userType]}
                </Box>
              </Flex>
            ))
          : loading && <LoadingPlaceholder />}
      </Flex>
    </Box>
  );
};

export default React.memo(UsersTable);
