import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import * as bodyScrollLock from 'body-scroll-lock';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#modal-root');

/* eslint-disable jsx-a11y/click-events-have-key-events */
const Modal = ({ children, isOpen, onRequestClose, ...props }) => {
  const modalWrapper = React.useRef();

  React.useEffect(() => {
    if (isOpen) {
      bodyScrollLock.disableBodyScroll(document.getElementById('__root'));
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }

    return () => {
      bodyScrollLock.clearAllBodyScrollLocks();
    };
  }, [isOpen]);

  return (
    <ReactModal
      {...props}
      isOpen={isOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(38, 47, 78, 0.35)',
          zIndex: 103,
          outline: 'none',
        },
        content: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
          WebkitOverflowScrolling: 'touch',
          outline: 'none',
          background: 'transparent',
          border: 'none',
          padding: 0,
        },
      }}
      closeTimeoutMS={200}
      onRequestClose={onRequestClose}
      parentSelector={() => document.querySelector('#modal-root')}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          min-height: 100%;
          justify-content: center;
          align-items: center;
        `}
        ref={modalWrapper}
        onClick={onRequestClose}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default React.memo(Modal);
