import { decamelize, decamelizeKeys } from 'humps';

export const TIMEOUT_ERROR = 'timeout';

export const joinUrl = (endpoint) => {
  const baseUrl = new URL(process.env.REACT_APP_API_PATH || '');
  const newUrl = new URL(endpoint, baseUrl);

  let url = new URL(`${baseUrl.pathname}${newUrl.pathname}`, baseUrl.origin).toString();

  if (!url.endsWith('/')) {
    url += '/';
  }

  return url + newUrl.search;
};

export const timeout = (ms) => new Promise((_, reject) => setTimeout(() => reject(new Error(TIMEOUT_ERROR)), ms));

export const isObject = (obj) => typeof obj === 'object' && obj !== null;

const jsonHeaders = ['Content-Type', 'application/json'];

export const extendHeaders = (headers, headerToSet) => {
  if (headers instanceof Headers) {
    headers = new Headers(headers);

    headers.append(...headerToSet);
  } else {
    headers = {
      ...headers,
      [headerToSet[0]]: headerToSet[1],
    };
  }

  return headers;
};

export const isGetMethod = (method) => !method || method === 'GET';

export const jsonSerializer = ({ body, ...init } = {}) => {
  if (!isGetMethod(init.method)) {
    init.headers = extendHeaders(init.headers, jsonHeaders);
  }

  return {
    ...init,
    body: Array.isArray(body) || isObject(body) ? JSON.stringify(decamelizeKeys(body)) : undefined,
  };
};

export const formDataSerializer = ({ body, ...init }) => {
  const copiedData = new FormData();

  body.forEach((value, key) => {
    copiedData.append(decamelize(key), value);
  });

  return {
    ...init,
    body,
  };
};

export const isFormDataRequest = (config) => config.body instanceof FormData;

export const serializeFormData = (body) =>
  Object.entries(body).reduce((formData, [key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((nestedValue) => {
        formData.append(decamelize(key), nestedValue);
      });
    } else {
      formData.append(decamelize(key), value);
    }

    return formData;
  }, new FormData());
