import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import NavLink from 'components/NavLink';
import useAlert from 'hooks/useAlert';
import * as discountFeatures from 'redux/features/discount';
import * as productFeatures from 'redux/features/product';
import createFormError, { errorToString } from 'utils/createFormError';

import DiscountForm from '../DiscountForm';

const AddDiscountCode = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const loaded = useSelector((state) => productFeatures.list.select(state)?.loaded);

  React.useEffect(() => {
    dispatch(
      productFeatures.list({
        params: {
          limit: 999,
          offset: 0,
        },
      })
    ).then((action) => {
      if (action.error) {
        alert.onFailure(errorToString(action));
      }
    });
  }, []);

  if (!loaded) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box variant="containerBox" p={3}>
      <DocumentTitle>Add Discount Code</DocumentTitle>
      <Link to="/payments">
        <NavLink>BACK TO SEARCH</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        Add Discount Code
      </Heading>
      <Form
        onSubmit={async ({ discountType, value, percent, expiresAt, ...values }, form) => {
          const body = {
            ...values,
            expiresAt: expiresAt ? format(expiresAt, 'yyyy-MM-dd') : null,
          };

          if (discountType === '0') {
            body.value = value ? Number(value) * 100 : 0;
            body.percent = 0;
          } else {
            body.value = 0;
            body.percent = percent || 0;
          }

          const action = await dispatch(
            discountFeatures.post({
              body,
            })
          );

          if (!action.error) {
            alert.onSuccess('Discount Code has been created.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          expiresAt: null,
          discountType: '0',
          products: [''],
        }}
        subscription={{
          ...FormError.formSubscriptions,
          values: true,
        }}
        mutators={arrayMutators}
      >
        {({ handleSubmit, values, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <DiscountForm values={values} editable />
            <Flex mt={3}>
              <FormError meta={meta} />
            </Flex>
            <Flex mt={4} justifyContent="center">
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Add Discount Code
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

AddDiscountCode.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(AddDiscountCode);
