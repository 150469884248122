export function stringifyQuery(query) {
  const params = new URLSearchParams();

  const append = (key, value) => {
    if (value !== undefined) {
      params.append(key, String(value));
    }
  };

  Object.keys(query).forEach((key) => {
    const current = query[key];

    if (Array.isArray(current)) {
      current.forEach((value) => {
        append(key, value);
      });
    } else {
      append(key, current);
    }
  });

  params.sort();

  return params.toString();
}

export function joinQuery(url, query) {
  return `${url}${query ? `/?${query}` : ''}`;
}
