import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'rebass';

const FormError = ({ meta }) => {
  const formError = meta.submitError && !meta.submitting && meta.submitFailed;

  if (formError) {
    return <Text variant="errorText">{meta.submitError}</Text>;
  }

  return null;
};

FormError.propTypes = {
  meta: PropTypes.shape({
    submitError: PropTypes.string,
    submitting: PropTypes.bool.isRequired,
    submitFailed: PropTypes.bool.isRequired,
    dirtySinceLastSubmit: PropTypes.bool.isRequired,
  }).isRequired,
};

FormError.formSubscriptions = {
  dirtySinceLastSubmit: true,
  submitError: true,
  submitting: true,
  submitFailed: true,
};

export default FormError;
