import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Text } from 'rebass';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import useAlert from 'hooks/useAlert';
import * as userFeatures from 'redux/features/user';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const UserProfilePassword = ({ match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.data[match.params.pk], shallowEqual);

  if (!user) {
    return <LoadingPlaceholder />;
  }

  return (
    <Box variant="containerBox" p={3}>
      <Form
        onSubmit={async ({ newPassword1, newPassword2, sendEmail }, form) => {
          const action = await dispatch(
            userFeatures.setPassword({
              body: {
                newPassword1,
                newPassword2,
                sendEmail,
              },
              pk: user.id,
            })
          );

          if (action.error) {
            alert.onFailure(errorToString(action));
            return createFormError(action);
          }

          alert.onSuccess('Password has been changed successfully.');
          setTimeout(form.reset);
        }}
        initialValues={{
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        }}
        validate={(values) => {
          if (values.newPassword1 !== values.newPassword2) {
            return {
              newPassword2: 'Passwords must match.',
            };
          }
        }}
        subscription={FormError.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field name="id" component={Input} label="ID" disabled />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field name="email" component={Input} label="Email Address" disabled type="text" />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field name="firstName" component={Input} label="First Name" placeholder="Enter first name" disabled />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field name="lastName" component={Input} label="Last Name" placeholder="Enter last name" disabled />
              </Box>
            </Flex>
            <Text variant="subtitle" mt={4}>
              Manually change password for user
            </Text>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field
                  name="newPassword1"
                  component={Input}
                  label="Password"
                  placeholder="Enter new password"
                  validate={checkRequired}
                />
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="newPassword2"
                  component={Input}
                  label="Password Again"
                  placeholder="Repeat new password"
                  validate={checkRequired}
                />
              </Box>
            </Flex>
            <Flex mt={2}>
              <Box width={1 / 2} pr={2}>
                <Field name="sendEmail" component={CheckboxInput} label="Notify user via email" type="checkbox" />
              </Box>
            </Flex>
            <Flex mt={4} justifyContent="center">
              <Box width={1 / 2} pr={2}>
                <Button variant="outline" onClick={form.reset}>
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} pl={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  Save Changes
                </Button>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

UserProfilePassword.propTypes = {
  match: PropTypes.object.isRequired,
};

export default React.memo(UserProfilePassword);
