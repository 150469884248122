import * as userProducts from 'redux/features/userProducts';

const initialState = {
  data: {},
  keys: {},
};

const authReducer = (state = initialState, action) => {
  if (
    action.type === userProducts.get.success ||
    action.type === userProducts.patch.success ||
    action.type === userProducts.disable.success ||
    action.type === userProducts.enable.success
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.payload.id]: action.payload,
      },
    };
  }

  if (action.type === userProducts.list.success) {
    return {
      keys: {
        ...state.keys,
        [action.meta.params.user]: {
          count: action.payload.count,
          data: action.payload.results.map(({ id }) => id),
        },
      },
      data: action.payload.results.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        state.data
      ),
    };
  }

  return state;
};

export default authReducer;
