import * as course from 'redux/features/course';

const initialState = {
  items: [],
  data: {},
};

const authReducer = (state = initialState, action) => {
  if (action.type === course.list.success) {
    return {
      items: action.payload.results,
      data: action.payload.results.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {}
      ),
    };
  }

  return state;
};

export default authReducer;
