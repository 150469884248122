import React from 'react';
import { css } from '@emotion/core';
import { Label } from '@rebass/forms';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Field, Form } from 'react-final-form';
import { Box, Flex, Heading, Text } from 'rebass';
import useSWR from 'swr';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import DatepickerInput from 'components/DatepickerInput';
import DocumentTitle from 'components/DocumentTitle';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { Link } from 'components/Nav/styles';
import NavLink from 'components/NavLink';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import { joinQuery, stringifyQuery } from 'utils/query';
import request, { matchError } from 'utils/request';
import { checkRequired } from 'utils/validation';

import TrackImageField from '../TrackImageField';

const AddTrack = ({ match, history }) => {
  const alert = useAlert();
  const hasItem = match.params.pk;

  const { data, mutate } = useSWR(hasItem ? `admin/tracks/${match.params.pk}` : null);

  const playlists = useSWR(
    joinQuery(
      'admin/playlists',
      stringifyQuery({
        limit: 500,
      })
    )
  );

  const tags = useSWR(
    joinQuery(
      'admin/tags',
      stringifyQuery({
        limit: 500,
      })
    )
  );

  const initialValues = React.useMemo(() => {
    if (data) {
      return {
        name: data.name,
        description: data.description,
        recordedAt: data.recordedAt ? parseISO(data.recordedAt) : undefined,
        published: data.published,
        image: data.image?.id,
        file: data.file,
        ...Object.fromEntries(data.playlists.map(({ id }) => [`playlist-${id}`, true])),
        ...Object.fromEntries(data.tags.map(({ id }) => [`tag-${id}`, true])),
      };
    }
  }, [data]);

  return (
    <Box variant="containerBox" p={3} maxWidth={696}>
      <DocumentTitle>{hasItem ? 'Edit Track' : 'Add New Track'}</DocumentTitle>
      <Link to="/content/tracks">
        <NavLink>BACK TO TRACKS</NavLink>
      </Link>
      <Heading textAlign="center" my={4}>
        {hasItem ? 'Edit' : 'Add'} Track
      </Heading>
      <Form
        onSubmit={async ({ description, image, name, published, recordedAt, file, ...other }, form) => {
          const formData = new FormData();

          if (file[0] instanceof File) {
            formData.append('file', file[0]);
          }

          formData.append('description', description);
          formData.append('image', image);
          formData.append('name', name);
          formData.append('published', !!published);
          formData.append('recorded_at', format(recordedAt, 'yyyy-MM-dd'));

          Object.entries(other).forEach(([key, value]) => {
            if (key.startsWith('playlist-') && value) {
              formData.append('playlists', key.split('playlist-')[1]);
            }

            if (key.startsWith('tag-') && value) {
              formData.append('tags', key.split('tag-')[1]);
            }
          });

          const response = await request(hasItem ? `/admin/tracks/${hasItem}` : '/admin/tracks/', {
            method: hasItem ? 'PATCH' : 'POST',
            body: formData,
            timeout: 1000 * 60 * 30,
          });

          if (!matchError(response)) {
            await mutate(response.payload, false);
            alert.onSuccess(`Track has been ${hasItem ? 'updated' : 'created'}.`);
            setTimeout(form.reset);
          } else {
            return response.toFormError();
          }
        }}
        subscription={Form.formSubscriptions}
        initialValues={initialValues}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            {data && (
              <Flex flexDirection="column">
                <Box>
                  <Text>ID: {data.id}</Text>
                </Box>
              </Flex>
            )}
            <Flex mt={3}>
              <Box width={1}>
                <Field name="file" component={FileInput} validate={checkRequired} label="File" />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="name"
                  component={Input}
                  validate={checkRequired}
                  label="Track Name"
                  placeholder="Example Tag"
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1}>
                <Field name="recordedAt" component={DatepickerInput} validate={checkRequired} label="Date Recorded" />
              </Box>
            </Flex>
            <Flex mt={2}>
              <Box width={1}>
                <Field name="published" component={CheckboxInput} label="Published" type="checkbox" />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="description"
                  component={Input}
                  validate={checkRequired}
                  label="Description"
                  placeholder="Example Description"
                  as="textarea"
                  css={css`
                    height: 280px;
                  `}
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1}>
                <Field name="image" component={TrackImageField} validate={checkRequired} label="Select Image" />
              </Box>
            </Flex>
            <Flex mt={3} flexDirection="column">
              <Label mb={2}>Select Playlists</Label>
              <Box width={1} maxHeight="280px" p={2} backgroundColor="#fff" overflowY="auto">
                {playlists.data
                  ? playlists.data.results.map((playlist) => (
                      <Field
                        key={playlist.id}
                        name={`playlist-${playlist.id}`}
                        component={CheckboxInput}
                        label={playlist.name}
                        type="checkbox"
                      />
                    ))
                  : playlists.isValidating && <LoadingPlaceholder multiple />}
              </Box>
            </Flex>
            <Flex mt={3} flexDirection="column">
              <Label mb={2}>Select Tags</Label>
              <Box width={1} maxHeight="280px" p={2} backgroundColor="#fff" overflowY="auto">
                {tags.data
                  ? tags.data.results.map((tag) => (
                      <Field
                        key={tag.id}
                        name={`tag-${tag.id}`}
                        component={CheckboxInput}
                        label={tag.name}
                        type="checkbox"
                      />
                    ))
                  : tags.isValidating && <LoadingPlaceholder multiple />}
              </Box>
            </Flex>
            {data && (
              <Flex mt={3} flexDirection="column">
                <Label mb={2}>Track Details</Label>
                <Box width={1} p={2} backgroundColor="#fff">
                  <Text mb={2}>
                    <Text fontWeight="bold" as="span">
                      File Name:
                    </Text>{' '}
                    {data.filename}
                  </Text>
                  <Text mb={2}>
                    <Text fontWeight="bold" as="span">
                      Track Duration:
                    </Text>{' '}
                    {data.duration}
                  </Text>
                  <Text>
                    <Text fontWeight="bold" as="span">
                      Date Uploaded:
                    </Text>{' '}
                    {format(parseISO(data.modifiedAt), 'MMM dd, yyyy hh:mm')}
                  </Text>
                </Box>
              </Flex>
            )}
            <FormError meta={meta} />
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Button
                  disabled={meta.pristine}
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset
                </Button>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Button variant="primary" type="submit" loading={meta.submitting}>
                  {hasItem ? 'Edit' : 'Add'} Track
                </Button>
              </Box>
            </Flex>
            <Flex mt={4} justifyContent="center">
              <Box>
                <WithModal
                  text="Are you sure you want to delete this audio track?"
                  renderButtons={({ close }) => (
                    <Flex>
                      <Box width={0.5}>
                        <Button variant="outline" onClick={close}>
                          Cancel
                        </Button>
                      </Box>
                      <Box width={0.5}>
                        <Button
                          variant="primary"
                          resolve={async () => {
                            const response = await request(`/admin/tracks/${match.params.pk}/`, {
                              method: 'DELETE',
                            });

                            if (!matchError(response)) {
                              history.push('/content/tracks');
                              alert.onSuccess('Audio track has been deleted successfully.');
                            } else {
                              close();
                              setTimeout(() => alert.onFailure(response.toString()), 200);
                            }
                          }}
                        >
                          Delete Audio Track
                        </Button>
                      </Box>
                    </Flex>
                  )}
                >
                  {({ open }) => (
                    <Button variant="nulled" onClick={open}>
                      <Flex alignItems="center">
                        <Text color="secondary" mr={2}>
                          Delete Audio Track
                        </Text>
                        <img
                          alt="Delete Track"
                          src={require('assets/delete.svg')}
                          css={css`
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                          `}
                        />
                      </Flex>
                    </Button>
                  )}
                </WithModal>
              </Box>
            </Flex>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default React.memo(AddTrack);
