import React from 'react';
import { css } from '@emotion/core';

const Loader = ({ black }) => (
  <div
    css={css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    `}
  >
    <div className="loader-2">
      <span
        css={
          black &&
          css`
            ::after {
              border-color: #000 !important;
            }
          `
        }
      />
    </div>
  </div>
);

export default React.memo(Loader);
