import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import format from 'date-fns/format';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import DocumentTitle from 'components/DocumentTitle';
import FormError from 'components/FormError';
import Input from 'components/Input';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import TableControls from 'components/TableControls';
import * as discountFeatures from 'redux/features/discount';

const Discounts = ({ history, match }) => {
  const dispatch = useDispatch();
  const productsLoading = useSelector((state) => discountFeatures.list.select(state)?.loading);

  const params = new URLSearchParams(history.location.search);

  const [page, setPage] = React.useState(() => {
    const pageParam = params.get('page');
    return pageParam ? Number(pageParam) - 1 : 0;
  });

  const [limit, setLimit] = React.useState(() => {
    const pageParam = params.get('limit');
    return pageParam ? Number(pageParam) : 10;
  });

  const [data, setData] = React.useState({});

  React.useEffect(() => {
    params.set('limit', limit);
    params.set('page', page + 1);

    history.push(`${match.url}?${params.toString()}`);

    dispatch(
      discountFeatures.list({
        params: {
          limit,
          offset: page * limit,
          code__icontains: params.get('search') || '',
        },
      })
    ).then(({ payload }) => {
      setData({
        ...data,
        count: payload.count,
        [page]: payload.results,
      });
    });
  }, [page, limit, params.get('search')]);

  return (
    <Box variant="containerBox" position="relative" maxWidth="1200px">
      <DocumentTitle>Search Discounts</DocumentTitle>
      <Flex>
        <Flex flex={0.3} />
        <Flex flex={0.4} justifyContent="center">
          <Heading textAlign="center" mb={3}>
            Search Discounts
          </Heading>
        </Flex>
        <Flex flex={0.3} justifyContent="flex-end">
          <Link to={`${match.url}/add`}>
            <Button variant="secondary" p={2}>
              Add New Discount
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Form
        onSubmit={({ search }) => {
          setPage(0);
          setData({
            count: data.count,
          });

          params.set('search', search);
          history.push(`${match.url}?${params.toString()}`);
        }}
        initialValues={{
          search: params.get('search') || '',
        }}
        subscription={{
          ...FormError.formSubscription,
          values: true,
        }}
      >
        {({ handleSubmit, values, ...meta }) => (
          <>
            <Box as="form" onSubmit={handleSubmit} mb={2}>
              <Box display="flex" alignItems="flex-start">
                <Box width={1}>
                  <Field name="search" component={Input} placeholder="Search by Discount" />
                </Box>
                <Button m={2} p={2} width="100px" height="42px" loading={meta.submitting} type="submit">
                  Search
                </Button>
                <Button
                  m={2}
                  mr={0}
                  p={2}
                  width="160px"
                  height="42px"
                  onClick={() => {
                    params.set('page', 1);
                    params.set('search', '');

                    history.push(`${match.url}?${params.toString()}`);
                  }}
                >
                  Clear Filters
                </Button>
              </Box>
              <FormError meta={meta} />
            </Box>
            <Box mt={4}>
              <Flex>
                <Box variant="th" width={0.2}>
                  Discount Code
                </Box>
                <Box variant="th" width={0.16}>
                  Products
                </Box>
                <Box variant="th" width={0.16}>
                  Promo Type
                </Box>
                <Box variant="th" width={0.16}>
                  Value
                </Box>
                <Box variant="th" width={0.16}>
                  Limit
                </Box>
                <Box variant="th" width={0.16}>
                  Expire Date
                </Box>
                <Box variant="th" width="80px" />
              </Flex>
              <Flex flexWrap="wrap">
                {data[page]
                  ? data[page].map((discountCode) => (
                      <Flex variant="row" width={1} key={discountCode.id}>
                        <Box variant="tb" width={0.2}>
                          {discountCode.code}
                        </Box>
                        <Box variant="tb" width={0.16}>
                          {discountCode.products.length === 0
                            ? '–'
                            : discountCode.products.map(({ name }) => name).join(', ')}
                        </Box>
                        <Box variant="tb" width={0.16}>
                          {discountCode.percent ? 'Percentage' : 'Flat Rate'}
                        </Box>
                        <Box variant="tb" width={0.16}>
                          {discountCode.percent ? `${discountCode.percent}%` : `$${discountCode.value / 100}`}
                        </Box>
                        <Box variant="tb" width={0.16}>
                          {`${discountCode.useCount}/${discountCode.limit || '–'}`}
                        </Box>
                        <Box variant="tb" width={0.16}>
                          {discountCode.expiresAt
                            ? format(new Date(discountCode.expiresAt), 'MM/dd/yyyy HH:mm a')
                            : 'Never Expires'}
                        </Box>
                        <Box variant="tb" width="80px">
                          <Flex width={1} justifyContent="flex-end">
                            <Link to={`${match.url}/${discountCode.id}`}>Edit</Link>
                          </Flex>
                        </Box>
                      </Flex>
                    ))
                  : productsLoading && <LoadingPlaceholder />}
              </Flex>
            </Box>
          </>
        )}
      </Form>
      {data.count ? (
        <TableControls
          page={page}
          limit={limit}
          count={data.count}
          setPage={setPage}
          setLimit={(l) => {
            setLimit(l);

            if (page * l > data.count) {
              setPage(Math.floor(data.count / l));
            }

            setData({
              count: data.count,
            });
          }}
        />
      ) : (
        !productsLoading && (
          <Flex p={4} py={5} alignItems="center" justifyContent="center">
            <Text>No matching items found.</Text>
          </Flex>
        )
      )}
    </Box>
  );
};

Discounts.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default React.memo(Discounts);
