import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';

import reducers from './reducers';

let enhancers = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {
  const composeWithDevTools = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  enhancers = composeWithDevTools(enhancers);
}

export default createStore(reducers, enhancers);
