import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import CheckboxInput from 'components/CheckboxInput';
import DatepickerInput from 'components/DatepickerInput';
import FormError from 'components/FormError';
import Input from 'components/Input';
import NavLink from 'components/NavLink';
import Select from 'components/Select';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import useUserRoute from 'hooks/useUserRoute';
import { USER_PRODUCT_ACCESS_TYPE } from 'redux/enums';
import * as productFeatures from 'redux/features/product';
import * as userProducts from 'redux/features/userProducts';
import createFormError, { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

// free=true, free_type !== 1
const EditProduct = ({ match, history }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [editable, setEditable] = React.useState(false);

  const user = useSelector((state) => state.users.data[match.params.pk], shallowEqual);
  const products = useSelector((state) => state.products.items, shallowEqual);
  const userProduct = useSelector((state) => state.userProducts.data[match.params.userProduct], shallowEqual);

  React.useEffect(() => {
    (async () => {
      await dispatch(
        productFeatures.list({
          params: {
            limit: 100,
            offset: 0,
          },
        })
      );
    })();

    if (!userProduct) {
      alert.onFailure('User Product not found.');
    }
  }, []);

  const [getRoute] = useUserRoute();

  if (!userProduct) {
    return <Redirect to={`/users/${user.id}/purchases`} />;
  }

  return (
    <Box variant="containerBox" p={3}>
      <Link to={getRoute(`/users/${user.id}/purchases`)}>
        <NavLink>
          {`GO BACK TO USER ${user.firstName ? `${user.firstName} ${user.lastName}` : ''}`.toUpperCase()}
        </NavLink>
      </Link>
      <Heading textAlign="center" my={5}>
        Edit Product
      </Heading>
      <Form
        onSubmit={async ({ disabled, ...body }) => {
          const patchAction = await dispatch(
            userProducts.patch({
              body: {
                ...body,
                user: user.id,
                free: true,
              },
              pk: userProduct.id,
            })
          );

          const disableAction = await dispatch(
            (disabled ? userProducts.disable : userProducts.enable)({
              pk: userProduct.id,
            })
          );

          const actions = [patchAction, disableAction];

          const hasError = actions.find((action) => action.error);

          if (hasError && actions.every((action) => action.error)) {
            return {
              ...createFormError(actions[0]),
              ...createFormError(actions[1]),
            };
          }

          if (hasError) {
            return createFormError(hasError);
          }

          alert.onSuccess('User Product has been updated successfully.');
          setEditable(false);
        }}
        subscription={Form.formSubscriptions}
        initialValues={{
          startedAt: userProduct.startedAt ? new Date(userProduct.startedAt) : null,
          product: userProduct.product.id,
          freeType: userProduct.freeType,
          comment: userProduct.comment,
          disabled: userProduct.disabled,
        }}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={3}>
              <Box width={1}>
                <Field
                  name="product"
                  component={Select}
                  label="Select Product"
                  validate={checkRequired}
                  disabled={!editable}
                >
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.slug}
                    </option>
                  ))}
                </Field>
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2} mr={2}>
                <Field
                  name="freeType"
                  component={Select}
                  label="Select Access Type"
                  validate={userProduct.freeType && checkRequired}
                  disabled={!editable}
                >
                  <option value="">Select access type</option>
                  {USER_PRODUCT_ACCESS_TYPE.filter(([id]) => id !== 1).map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Field>
              </Box>
              <Box width={1 / 2} ml={2}>
                <Field
                  name="startedAt"
                  component={DatepickerInput}
                  label="Purchase Date"
                  showTimeSelect
                  timeIntervals={10}
                  timeFormat="HH:mm"
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  maxDate={new Date()}
                  disabled={!editable}
                />
              </Box>
            </Flex>
            <Flex mt={3}>
              <Box width={1 / 2}>
                <Field
                  name="disabled"
                  component={CheckboxInput}
                  label="User Product Disabled"
                  type="checkbox"
                  disabled={!editable}
                />
              </Box>
            </Flex>
            <Box width={1} mt={3}>
              <Field
                name="comment"
                component={Input}
                label="Notes"
                as="textarea"
                css={css`
                  height: 280px;
                `}
                tip={'Enter notes about this product. E.g. "Direct invoice using PayPal."'}
                disabled={!editable}
              />
            </Box>
            <FormError meta={meta} />
            {editable && (
              <Flex mt={3}>
                <Box width={1 / 2} mr={2}>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setEditable(false);
                      form.reset();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={1 / 2} ml={2}>
                  <Button variant="primary" type="submit" loading={meta.submitting}>
                    Save Changes
                  </Button>
                </Box>
              </Flex>
            )}
          </form>
        )}
      </Form>
      {!editable && (
        <Flex alignItems="center" justifyContent="center" width={1} mt={3}>
          <Button variant="nulled" width="initial" onClick={() => setEditable(true)}>
            <Flex alignItems="center">
              <Text color="secondary" mr={2}>
                Edit Product
              </Text>
              <img
                alt="Edit Profile"
                src={require('assets/pencil.svg')}
                css={css`
                  width: 14px;
                  height: 13px;
                  object-fit: contain;
                `}
              />
            </Flex>
          </Button>
        </Flex>
      )}
      {userProduct.amountPaid === 0 && (
        <Flex mt={4} justifyContent="center">
          <Box>
            <WithModal
              text="Are you sure you want to delete this user product?"
              renderButtons={({ close }) => (
                <Flex>
                  <Box width={0.5}>
                    <Button variant="outline" onClick={close}>
                      Cancel
                    </Button>
                  </Box>
                  <Box width={0.5}>
                    <Button
                      variant="primary"
                      resolve={async () => {
                        dispatch(
                          userProducts.del({
                            pk: userProduct.id,
                          })
                        ).then((action) => {
                          if (!action.error) {
                            history.push(`/users/${user.id}/purchases`);
                            alert.onSuccess('Product has been deleted successfully.');
                          } else {
                            close();
                            setTimeout(() => alert.onFailure(errorToString(action)), 200);
                          }
                        });
                      }}
                    >
                      Delete Product
                    </Button>
                  </Box>
                </Flex>
              )}
            >
              {({ open }) => (
                <Button variant="nulled" onClick={open}>
                  <Flex alignItems="center">
                    <Text color="secondary" mr={2}>
                      Delete Product
                    </Text>
                    <img
                      alt="Delete Product"
                      src={require('assets/delete.svg')}
                      css={css`
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                      `}
                    />
                  </Flex>
                </Button>
              )}
            </WithModal>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

EditProduct.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(EditProduct);
