import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { Box } from 'rebass';

export const IconContainer = styled(Box)`
  width: 25px;
  display: flex;
  align-items: center;
`;

export const Link = styled(NavLink)`
  &.active {
    font-weight: bold;
  }
`;
