import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import AddUser from './components/AddUser';
import SearchUsers from './components/SearchUsers';
import UserProfile from './components/UserProfile';

const Users = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/search`} component={SearchUsers} />
    <Route path={`${match.path}/add`} component={AddUser} />
    <Route path={`${match.path}/:pk`} component={UserProfile} />
    <Redirect to={`${match.path}/search`} />
  </Switch>
);

Users.propTypes = {
  match: PropTypes.object.isRequired,
};

export default React.memo(Users);
