import * as productFeatures from 'redux/features/product';

const initialState = {
  items: [],
  data: {},
};

const authReducer = (state = initialState, action) => {
  if (action.type === productFeatures.list.success) {
    const results = action.payload.results.map((product) => ({
      ...product,
      slug: `${product.name}: $${product.fullPrice / 100} ${product.shortName ? `(${product.shortName})` : ''}`,
    }));

    return {
      items: results,
      data: results.reduce(
        (pv, cv) => ({
          ...pv,
          [cv.id]: cv,
        }),
        {}
      ),
    };
  }

  return state;
};

export default authReducer;
