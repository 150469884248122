import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import FileInput from 'components/FileInput';
import FormError from 'components/FormError';
import TippedLabel from 'components/TippedLabel';
import useAlert from 'hooks/useAlert';
import * as photosFeatures from 'redux/features/photos';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const BulkUploadPhotos = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  return (
    <Flex mt={4} flexDirection="column">
      <Heading mb={2} fontSize={3}>
        PHOTOS BULK UPLOAD
      </Heading>
      <Form
        onSubmit={async ({ images, csv }, form) => {
          const formData = new FormData();

          formData.append('csv', csv[0]);

          images.forEach((image, index) => {
            formData.append(`image_${index}`, image);
          });

          const action = await dispatch(
            photosFeatures.bulkUpload({
              body: formData,
            })
          );

          if (!action.error) {
            alert.onSuccess('Photos have been uploaded.');
            setTimeout(form.reset);
          } else {
            return createFormError(action);
          }
        }}
        initialValues={{
          images: [],
        }}
        subscription={Form.formSubscriptions}
      >
        {({ handleSubmit, form, ...meta }) => (
          <form onSubmit={handleSubmit}>
            <Flex mt={2} flexDirection="row" alignItems="center">
              <Flex width={160} mr={3}>
                <TippedLabel>Select Image Files:</TippedLabel>
              </Flex>
              <Flex flexDirection="column">
                <Box width={1}>
                  <Field
                    name="images"
                    component={FileInput}
                    type="file"
                    accept="image/jpeg, image/gif, image/png"
                    multiple
                    validate={checkRequired}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex mt={2} flexDirection="row" alignItems="center">
              <Flex width={160} mr={3}>
                <TippedLabel>Upload Description:</TippedLabel>
              </Flex>
              <Flex flexDirection="column">
                <Box>
                  <Field
                    name="csv"
                    component={FileInput}
                    type="file"
                    accept="text/csv"
                    multiple={false}
                    validate={checkRequired}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box mx={3}>
                  <Button
                    variant="primary"
                    type="submit"
                    loading={meta.submitting}
                    css={css`
                      padding: 12px 16px;
                    `}
                  >
                    Upload
                  </Button>
                </Box>
              </Flex>
              <Flex>
                <TippedLabel
                  tip={`Select one or more images at the same time (bulk upload). Once the images selected, select the CSV files with captions. Then click "upload" to upload all photos and CSV fie.`}
                />
              </Flex>
            </Flex>
            <FormError meta={meta} />
          </form>
        )}
      </Form>
      <Flex flexDirection="row" alignItems="center" mt={2}>
        <Flex width={160} mr={3}>
          <Text>Template File:</Text>
        </Flex>
        <Flex>
          <Button
            primary
            resolve={async () => {
              try {
                const response = await fetch(process.env.REACT_APP_API_PATH + '/admin/photos/csv_file/', {
                  headers: {
                    Authorization: `JWT ${token}`,
                  },
                });

                if (response.status === 200) {
                  const blob = await response.blob();
                  const a = document.createElement('a');

                  a.href = window.URL.createObjectURL(blob);
                  a.download = 'photos template.csv';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                } else {
                  alert.onFailure('API error has occured while downloading the template file.');
                }
              } catch {
                alert.onFailure('Unknown error has occured while downloading the template file.');
              }
            }}
            css={css`
              padding: 12px 16px;
            `}
          >
            Download CSV File
          </Button>
        </Flex>
        <Flex ml={3}>Download template CSV file for upload</Flex>
      </Flex>
    </Flex>
  );
};

BulkUploadPhotos.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(BulkUploadPhotos);
