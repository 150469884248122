import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Link } from 'rebass';

const NavLink = ({ children, reversed, ...props }) => (
  <Link
    display="flex"
    as="p"
    variant="nav"
    flexDirection={reversed ? 'row-reverse' : 'row'}
    css={css`
      align-items: center;
    `}
    {...props}
  >
    <img
      alt={children}
      src={require('assets/chevron.svg')}
      css={css`
        margin: 0 4px 0;
        height: 12px;
        width: 12px;
        object-fit: contain;
        object-position: left;
        transform: ${reversed ? 'rotateY(-180deg)' : 'none'};
      `}
    />
    {children}
  </Link>
);

NavLink.propTypes = {
  children: PropTypes.string.isRequired,
};

export default React.memo(NavLink);
